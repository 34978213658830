// Import React hooks
import { useRef } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';



// import required modules
import { Autoplay, Pagination } from 'swiper';

import jclay from './assets/J-clay-logo.png';
import coenen from './assets/Coenen-logo.png';
import tolsen from './assets/Tolsen-logo.png';
import heydecke from './assets/Heydecke.png';
import amg from './assets/Amg-recruiting-logo.png';
import conmoto from './assets/Conmoto-logo.png';
import lineup from './assets/Line-up-logo.png';
import cds from './assets/Cds-marketing-logo.png';
import zmyle from './assets/zmyle-logo.png';
import mwb from './assets/Malaga-workbay-logotipo.png';
import sg06ah from './assets/Sg-coesfeld-06-logo.png';
import promalaga from './assets/Pro-malaga-logo.png';
import dmm from './assets/Digital-marketing-malaga.png';
import tramontina from './assets/Tramontina.png';
import vondiem from './assets/VONDIEM.png';
import actaco from './assets/ACTA&CO.png';
import concrete from './assets/Concrete.png';
import hy from './assets/HY.png';
import solit from './assets/solit.marketing.png'

const partners = [
  {
    name: 'promalaga',
    imageSrc: promalaga,
    imageAlt: 'Pro Málaga Logo',
  },
  {
    name: 'dmm',
    imageSrc: dmm,
    imageAlt: 'Digital Marketing Málaga Logo',
  },
  {
    name: 'Coenen Publishing',
    imageSrc: coenen,
    imageAlt: 'Coenen Publishing Logo',
  },
  {
    name: 'J.Clay Socks',
    imageSrc: jclay,
    imageAlt: 'J.Clay Logo',
  },
  {
    name: 'solit.marketing',
    imageSrc: solit,
    imageAlt: 'solit Logo',
  },
  {
    name: 'HelpYourself',
    imageSrc: hy,
    imageAlt: 'HelpYourself Logo',
  },
  {
    name: 'acta&co',
    imageSrc: actaco,
    imageAlt: 'ACTA&CO Logo',
  },
  {
    name: 'concrete',
    imageSrc: concrete,
    imageAlt: 'concrete Logo',
  },
  {
    name: 'tramontina',
    imageSrc: tramontina,
    imageAlt: 'Tramontina Logo',
  },
  {
    name: 'VONDIEM',
    imageSrc: vondiem,
    imageAlt: 'VONDIEM Logo',
  },
  {
    name: 'Heyecke',
    imageSrc: heydecke,
    imageAlt: 'Heydecke Logo',
  },
  {
    name: 'Tolsen Tools',
    imageSrc: tolsen,
    imageAlt: 'Tolsen Tools Logo',
  },
  {
    name: 'AMG Recruiting',
    imageSrc: amg,
    imageAlt: 'AMG Recruiting Logo',
  },
  {
    name: 'Conmoto',
    imageSrc: conmoto,
    imageAlt: 'conmoto logo',
  },
  {
    name: 'LineUp',
    imageSrc: lineup,
    imageAlt: 'LineUp Logo',
  },
  {
    name: 'cds marketing online',
    imageSrc: cds,
    imageAlt: 'cds marketing online Logo',
  },
  {
    name: 'zmyle',
    imageSrc: zmyle,
    imageAlt: 'zmyle Logo',
  },
  {
    name: 'mwb',
    imageSrc: mwb,
    imageAlt: 'MálagaWorkbay Logo',
  },
  {
    name: 'SG Coesfeld 06',
    imageSrc: sg06ah,
    imageAlt: 'SG Coesfeld 06 Logo',
  }
]


function Partners() {
    const progressCircle = useRef(null);
  const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
      };
  return (
    <div id="partners" className="bg-gradient-to-r from-gray-400 to-gray-200 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        breakpoints={{
          320: { // Add a specific breakpoint for small mobile screens
            slidesPerView: 2, // Adjust the number of slides to show on mobile
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 50,
          },
        }}
        modules={[Autoplay, Pagination]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
        {partners.map((partner) => (
            <SwiperSlide key={partner.name} className="rounded-full">
                <img
                key={partner.name}
                className="col-span-1 max-h-48 w-full object-contain grayscale hover:grayscale-0"
                src={partner.imageSrc}
                alt={partner.imageAlt}
                width={280}
                />
            </SwiperSlide>
            ))}
            <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </div>
      </Swiper>
      </div>
      </div>
  );
}

export default Partners;
