import { Link } from 'react-router-dom';
import logo from '../smplx-logo-black.png';


function Header() {
 

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">smplx.</span>
            <img className="h-8 w-auto" src={logo} alt="smplx logo" />
          </Link>
        </div>
        
      </nav>
      
    </header>
  )
}


export default Header;