import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import conmoto from "./assets/conmoto-mockup.png";
import jclay from "./assets/j-clay_mockup.png";
import ww from "./assets/ww-mockup.png";
import zmyle from "./assets/zmyle-backoffice-mockup.png"


// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

import "./swiper.css";

// import required modules
import { FreeMode, Thumbs } from "swiper";

export default function App() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <Swiper
        
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Thumbs]}
        className="max-w-5xl mx-auto px-6 lg:px-8 mySwiper2"
      >
        <SwiperSlide>
          <img src={jclay} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ww} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={zmyle} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={conmoto} />
        </SwiperSlide>
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Thumbs]}
        className="max-w-5xl mx-auto px-6 lg:px-8 mySwiper"
      >
        <SwiperSlide>
          <img src={jclay} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={ww} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={zmyle} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={conmoto} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
