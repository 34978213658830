import mo from './assets/mo-otten.jpeg'

export default function Example() {
    return (
      <section className="relative isolate overflow-hidden bg-white py-24 px-6 sm:py-32 lg:px-8">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <img className="mx-auto h-12" src="https://cdn.shopify.com/s/files/1/0281/2690/4380/files/J.Clay-Logo-Auge_90x@2x.png?v=1614737947" alt="J.Clay Logo" />
          <figure className="mt-10">
            <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
              <p>
                “Umsetzung ist immer auf hohem Niveau und wir schätzen die Bereicherung durch Ideen und neue Denkansätze.”
              </p>
            </blockquote>
            <figcaption className="mt-10">
              <img
                className="mx-auto h-10 w-10 rounded-full"
                src={mo}
                alt="Moritz Otten"
              />
              <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                <div className="font-semibold text-gray-900">Moritz Otten</div>
                <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <div className="text-gray-600">CEO <a href="https://jclay-socks.com" className="text-secondary-700" target="_blank" rel="noopener noreferrer"> J.Clay Socks</a></div>
              </div>
            </figcaption>
          </figure>
        </div>
      </section>
    )
  }
  