import { Helmet } from 'react-helmet';

function Datenschutz() {
    return (
      <div>
        <Helmet>
          <title>smplx. - Datenschutz</title>
        </Helmet>
        
        <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none">
            <div className="lg:pr-8 lg:pt-4">
                <div className="">
                
                
                
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-secondary-700 sm:text-4xl">Datenschutzerklärung</h1>

<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="einleitung-ueberblick" >Einleitung und Überblick</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben diese Datenschutzerklärung (Fassung 02.05.2023-312492666) verfasst, um Ihnen gemäß der Vorgaben der <a  href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312492666#d1e2269-1-1" target="_blank" rel="noreferrer noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
<strong >Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong >Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen geboten und <strong >Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="anwendungsbereich" >Anwendungsbereich</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Social Media Auftritte und E-Mail-Kommunikation</li>
<li className="mt-6 text-lg leading-8 text-gray-600">mobile Apps für Smartphones und andere Geräte</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="rechtsgrundlagen" >Rechtsgrundlagen</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a  href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
<li className="mt-6 text-lg leading-8 text-gray-600">
<strong >Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">
<strong >Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">
<strong >Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">
<strong >Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p className="mt-6 text-lg leading-8 text-gray-600">Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">In <strong >Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong >Datenschutzgesetz</strong>), kurz <strong >DSG</strong>.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">In <strong >Deutschland</strong> gilt das <strong >Bundesdatenschutzgesetz</strong>, kurz<strong > BDSG</strong>.</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="kontaktdaten-verantwortliche" >Kontaktdaten des Verantwortlichen</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
<span  className="font-medium">Claudio Gerlich<br />
Osterwicker Str. 20, 48653 Coesfeld</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">E-Mail: <a href="mailto:hallo@smplx.media">hallo@smplx.media</a>
<br />
Telefon: <a href="tel:+49 (0) 2541 9321402">+49 (0) 2541 9321402</a>
<br />
Impressum: <a href="https://smplx.media/impressum/">https://smplx.media/impressum/</a>
</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="speicherdauer" >Speicherdauer</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="rechte-dsgvo" >Rechte laut Datenschutz-Grundverordnung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">zu welchem Zweck wir die Verarbeitung durchführen;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">wie lange die Daten gespeichert werden;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
<li className="mt-6 text-lg leading-8 text-gray-600">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
</ul>
</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
</ul>
</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a  href="https://www.dsb.gv.at/?tid=312492666" target="_blank" rel="noreferrer noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a  href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noreferrer noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900" id="nordrhein-westfalen-datenschutzbehoerde" >Nordrhein-Westfalen Datenschutzbehörde</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Landesbeauftragte für Datenschutz:</strong> Bettina Gayk<br />
<strong >Adresse: </strong>Kavalleriestraße 2-4, 40213 Düsseldorf<br />
<strong >Telefonnr.:</strong> 02 11/384 24-0<br />
<strong >E-Mail-Adresse:</strong> poststelle@ldi.nrw.de<br />
<strong >Website: </strong>
<a  href="https://www.ldi.nrw.de/?tid=312492666" target="_blank" rel="noreferrer noopener">https://www.ldi.nrw.de/</a>
</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="sicherheit-datenverarbeitung" >Sicherheit der Datenverarbeitung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="tls-verschluesselung-https" >TLS-Verschlüsselung mit https</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br />
Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch Technikgestaltung (<a  href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312492666" target="_blank" rel="noreferrer noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="kommunikation" >Kommunikation</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Kommunikation Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
&#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
&#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
&#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Betroffene Personen</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Telefon</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">E-Mail</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Online Formulare</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlagen</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
</ul>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="auftragsverarbeitungsvertrag-avv" >Auftragsverarbeitungsvertrag (AVV)</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">In diesem Abschnitt möchten wir Ihnen erklären, was ein Auftragsverarbeitungsvertrag ist und warum dieser benötigt wird. Weil das Wort &#8220;Auftragsverarbeitungsvertrag&#8221; ein ziemlicher Zungenbrecher ist, werden wir hier im Text auch öfters nur das Akronym AVV benutzen. Wie die meisten Unternehmen arbeiten wir nicht alleine, sondern nehmen auch selbst Dienstleistungen anderer Unternehmen oder Einzelpersonen in Anspruch.  Durch die Einbeziehung verschiedener Unternehmen bzw. Dienstleister kann es sein, dass wir  personenbezogene Daten zur Verarbeitung weitergeben. Diese Partner fungieren dann als Auftragsverarbeiter, mit denen wir einen Vertrag, den sogenannten Auftragsverarbeitungsvertrag (AVV), abschließen. Für Sie am wichtigsten zu wissen ist, dass die Verarbeitung Ihrer personenbezogenen Daten ausschließlich nach unserer Weisung erfolgt und durch den AVV geregelt werden muss.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wer sind Auftragsverarbeiter?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir sind als Unternehmen und Websiteinhaber für alle Daten, die wir von Ihnen verarbeiten verantwortlich. Neben den Verantwortlichen kann es auch sogenannte Auftragsverarbeiter geben. Dazu zählt jedes Unternehmen bzw. jede Person, die in unserem Auftrag personenbezogene Daten verarbeitet. Genauer und nach der DSGVO-Definition gesagt: jede natürliche oder juristische Person, Behörde, Einrichtung oder eine andere Stelle, die in unserem Auftrag personenbezogene Daten verarbeitet, gilt als Auftragsverarbeiter. Auftragsverarbeiter können folglich Dienstleister wie Hosting- oder Cloudanbieter, Bezahlungs- oder Newsletter-Anbieter oder große Unternehmen wie beispielsweise Google oder Microsoft sein.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Zur besseren Verständlichkeit der Begrifflichkeiten hier ein Überblick über die drei Rollen in der DSGVO:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Betroffener</strong> (Sie als Kunde oder Interessent) → <strong >Verantwortlicher</strong> (wir als Unternehmen und Auftraggeber) → <strong >Auftragsverarbeiter</strong> (Dienstleister wie z. B. Webhoster oder Cloudanbieter)</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Inhalt eines Auftragsverarbeitungsvertrages</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wie bereits oben erwähnt, haben wir mit unseren Partnern, die als Auftragsverarbeiter fungieren, einen AVV abgeschlossen. Darin wird allen voran festgehalten, dass der Auftragsverarbeiter die zu bearbeitenden Daten ausschließlich gemäß der DSGVO verarbeitet. Der Vertrag muss schriftlich abgeschlossen werden, allerdings gilt in diesem Zusammenhang auch der elektronische Vertragsabschluss als „schriftlich“. Erst auf der Grundlage des Vertrags erfolgt die Verarbeitung der personenbezogenen Daten. Im Vertrag muss folgendes enthalten sein:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Bindung an uns als Verantwortlichen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Pflichten und Rechte des Verantwortlichen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Kategorien betroffener Personen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Art der personenbezogenen Daten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Art und Zweck der Datenverarbeitung</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Gegenstand und Dauer der Datenverarbeitung</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Durchführungsort der Datenverarbeitung</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">Weiters enthält der Vertrag alle Pflichten des Auftragsverarbeiters. Die wichtigsten Pflichten sind:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Maßnahmen zur Datensicherheit zu gewährleisten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">mögliche technische und organisatorischen Maßnahmen zu treffen, um die Rechte der betroffenen Person zu schützen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">ein Daten-Verarbeitungsverzeichnis zu führen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">auf Anfrage der Datenschutz-Aufsichtsbehörde mit dieser zusammenzuarbeiten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">eine Risikoanalyse in Bezug auf die erhaltenen personenbezogenen Daten durchzuführen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Sub-Auftragsverarbeiter dürfen nur mit schriftlicher Genehmigung des Verantwortlichen beauftragt werden</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">Wie so eine AVV konkret aussieht, können Sie sich beispielsweise unter <a  href="https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html">https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html</a> ansehen. Hier wird ein Mustervertrag vorgestellt.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="cookies" >Cookies</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Cookies Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
&#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
&#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was sind Cookies?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">So können zum Beispiel Cookie-Daten aussehen:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _ga<br />
<strong >Wert:</strong> GA1.2.1326744211.152312492666-9<br />
<strong >Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
<strong >Ablaufdatum:</strong> nach 2 Jahren</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Mindestens 4096 Bytes pro Cookie</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Mindestens 50 Cookies pro Domain</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Mindestens 3000 Cookies insgesamt</li>
</ul>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Arten von Cookies gibt es?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Man kann 4 Arten von Cookies unterscheiden:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Unerlässliche Cookies<br />
</strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Zweckmäßige Cookies<br />
</strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Zielorientierte Cookies<br />
</strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Werbe-Cookies<br />
</strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a  href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Zweck der Verarbeitung über Cookies</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Speicherdauer von Cookies</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<a  href="https://support.google.com/chrome/answer/95647?tid=312492666" target="_blank" rel="noreferrer" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<a  href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=312492666" target="_blank" rel="noreferrer" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<a  href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=312492666" target="_blank" rel="noreferrer" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<a  href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=312492666">Internet Explorer: Löschen und Verwalten von Cookies</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<a  href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=312492666">Microsoft Edge: Löschen und Verwalten von Cookies</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine <strong >Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen <strong >berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="bewerbungsdaten" >Bewerbungsdaten</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Bewerbungsdaten Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Nutzer, die sich bei uns für eine Arbeitsstelle bewerben<br />
&#x1f91d; Zweck: Abwicklung eines Bewerbungsverfahrens<br />
&#x1f4d3; Verarbeitete Daten: Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer, Qualifikationsnachweise (Zeugnisse), evtl. Daten besonderer Kategorien.<br />
&#x1f4c5; Speicherdauer: bei erfolgreicher Bewerbung bis zum Ende des Dienstverhältnisses. Anderenfalls werden die Daten nach dem Bewerbungsverfahren gelöscht oder mit Ihrer Einwilligung für einen gewissen Zeitraum gespeichert.<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO), Art. 6 Abs 1 lit. b DSGVO (Vertrag), Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">
<strong >Was sind Bewerbungsdaten? </strong>
</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie können sich bei uns per E-Mail, Online-Formular oder über ein Recruiting-Tool für eine Arbeitsstelle in unserem Unternehmen bewerben. Alle Daten, die wir im Rahmen einer Bewerbung von Ihnen erhalten und verarbeiten, zählen zu den Bewerbungsdaten. Dabei geben Sie immer auch personenbezogene Daten wie etwa Namen, Geburtsdatum, Adresse und Telefonnummer preis.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verarbeiten wir Bewerbungsdaten?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verarbeiten Ihre Daten, damit wir ein ordentliches Auswahlverfahren in Bezug auf die ausgeschriebene Stelle betreiben können. Zusätzlich halten wir auch gerne Ihre Bewerbungsunterlagen in unserem Bewerbungsarchiv. Denn oft kommt es vor, dass für die ausgeschriebene Stellen eine Zusammenarbeit aus den unterschiedlichsten Gründen nicht klappt, wir aber von Ihnen und Ihrer Bewerbung beeindruckt sind und uns eine zukünftige Zusammenarbeit sehr gut vorstellen können. Sofern Sie uns dafür Ihre Einwilligung geben, archivieren wir Ihre Unterlagen, damit wir Sie für zukünftige Aufgaben in unserem Unternehmen leicht kontaktieren können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir garantieren Ihnen, dass wir besonders behutsam mit Ihren Daten umgehen und immer nur innerhalb des rechtlichen Rahmens Ihre Daten verarbeiten. Auch innerhalb unseres Unternehmens werden Ihre Daten nur an Personen weitergeleitet, die unmittelbar mit Ihrer Bewerbung zu tun haben. Kurz gesagt: Ihre Daten sind bei uns sicher aufgehoben!</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie sich zum Beispiel bei uns per E-Mail bewerben, erhalten wir natürlich, wie oben erwähnt, auch personenbezogene Daten. Selbst die E-Mail-Adresse zählt schon zu den personenbezogenen Daten. Verarbeitet werden im Zuge eines Bewerbungsverfahrens allerdings nur jene Daten, die für unsere Entscheidung, ob wir Sie in unserem Team begrüßen wollen oder nicht, relevant sind.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Welche Daten genau verarbeitet werden, hängt in erster Linie von der Stellenausschreibung ab. Meistens handelt es sich aber um Namen, Geburtsdatum, Kontaktdaten und Qualifikationsnachweise. Wenn Sie die Bewerbung über ein Online-Formular einreichen, werden die Daten verschlüsselt an uns weitergegeben. Schicken Sie uns die Bewerbung per E-Mail, findet diese Verschlüsselung nicht statt. Für den Weg der Übertragung können wir somit keine Verantwortung übernehmen. Sobald die Daten aber auf unseren Servern sind, sind wir für die rechtmäßige Handhabung Ihrer Daten verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Während eines Bewerbungsvorgangs können neben den oben genannten Daten auch Informationen zu Ihrer Gesundheit oder Ihrer ethnischen Herkunft angefragt werden, damit wir und Sie die Rechte in Bezug auf Arbeitsrecht, sozialer Sicherheit und Sozialschutz ausüben können und gleichzeitig den dazu entsprechenden Pflichten nachkommen können. Bei diesen Daten handelt es sich um Daten besonderer Kategorien.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und verarbeiten:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Name</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Kontaktadresse</li>
<li className="mt-6 text-lg leading-8 text-gray-600">E-Mail-Adresse</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Telefonnummer</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Geburtsdatum</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Informationen, die aus Anschreiben und Lebenslauf hervorgehen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Qualifikationsnachweise (z. B.) Zeugnisse</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Daten besonderer Kategorien (z. B. ethnische Herkunft, Gesundheitsdaten, religiöse Überzeugungen)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Metadaten (IP-Adresse, Geräte-Informationen)</li>
</ul>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn wir Sie als Teammitglied in unserem Unternehmen aufnehmen, werden Ihre Daten für den Zweck des Arbeitsverhältnisses weiterverarbeitet und mindestens bis zur Beendigung des Arbeitsverhältnisses bei uns aufbewahrt. Alle Bewerbungsunterlagen kommen dann in Ihre Mitarbeiterakte.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bieten wir Ihnen die Arbeitsstelle nicht an, lehnen Sie unser Angebot ab oder ziehen Ihre Bewerbung zurück, können wir aufgrund des berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO) Ihre Daten bis zu 6 Monate nach Abschluss des Bewerbungsverfahrens aufbewahren. Danach werden sowohl Ihre elektronischen Daten als auch alle Daten aus physischen Bewerbungsunterlagen vollständig gelöscht bzw. vernichtet. Wir behalten uns Ihre Daten etwa, damit wir noch etwaige Nachfragen beantworten können oder, damit wir im Falle eines Rechtsstreits Nachweise über die Bewerbung vorlegen können. Falls sich ein Rechtsstreit anbahnt und wir eventuell die Daten nach Ablauf der 6 Monate immer noch benötigen, werden wir die Daten erst dann löschen, wenn es keinen Grund mehr zur Aufbewahrung gibt. Sofern es gesetzliche Aufbewahrungspflichten zu erfüllen gibt, müssen wir die Daten grundsätzlich länger als 6 Monate speichern.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Weiters können wir Ihre Daten auch länger aufbewahren, wenn Sie dafür eine spezielle Einwilligung erteilt haben. Das machen wir zum Beispiel, wenn wir uns in Zukunft eine Zusammenarbeit mit Ihnen gut vorstellen können. Dann ist es hilfreich Ihre Daten archiviert zu haben, um Sie problemlos erreichen können. In diesem Fall kommen die Daten in unser Bewerberpool. Selbstverständlich können Sie Ihre Einwilligung zur längeren Aufbewahrung Ihrer Daten jederzeit widerrufen. Erfolgt kein Widerruf und geben Sie keine neue Einwilligung ab, werden Ihre Daten spätestens nach 2 Jahren gelöscht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)  und Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien).</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Nehmen wir Sie in unser Bewerbertool auf, passiert dies auf der Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Wir weisen Sie darauf hin, dass Ihre Zustimmung in unser Bewerbungspool freiwillig ist, keinen Einfluss auf den Bewerbungsprozess hat und Sie jederzeit die Möglichkeit haben, Ihre Einwilligung zu widerrufen. Die Rechtmäßigkeit der Verarbeitung bis zum Zeitpunkt des Widerrufs bleibt davon unberührt.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gemäß Art. 9 Abs. 2 lit. c. DSGVO. Für Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, für die medizinische Diagnostik, für die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gemäß Art. 9 Abs. 2 lit. h. DSGVO. Wenn Sie freiwillig Daten der besonderen Kategorien mitteilen, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="kundendaten" >Kundendaten</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Kundendaten Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Kunden bzw. Geschäfts- und Vertragspartner<br />
&#x1f91d; Zweck: Erbringung der vertraglich oder vorvertraglich vereinbarten Leistungen einschließlich zugehörige Kommunikation<br />
&#x1f4d3; Verarbeitete Daten: Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer, Zahlungsinformationen (wie z. B. Rechnungen und Bankdaten), Vertragsdaten (wie z. B. Laufzeit und Gegenstand des Vertrags), IP-Adresse, Bestelldaten<br />
&#x1f4c5; Speicherdauer: die Daten werden gelöscht, sobald sie zur Erbringung unserer geschäftlichen Zwecke nicht mehr erforderlich sind und es keine gesetzliche Aufbewahrungspflicht gibt.<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO), Vertrag (Art. 6 Abs 1 lit. b DSGVO)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was sind Kundendaten?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Damit wir unser Service bzw. unsere vertraglichen Leistungen anbieten können, verarbeiten wir auch Daten unserer Kunden und Geschäftspartner. Unter diesen Daten befinden sich immer auch personenbezogene Daten. Unter Kundendaten versteht man alle Informationen, die auf Basis einer vertraglichen oder vorvertraglichen Zusammenarbeit verarbeitet werden, um die angebotenen Leistungen erbringen zu können. Kundendaten sind also alle gesammelten Informationen, die wir über unsere Kunden erheben und verarbeiten.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verarbeiten wir Kundendaten?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Es gibt viele Gründe, warum wir Kundendaten sammeln und verarbeiten. Der wichtigste ist, dass wir zur Bereitstellung unserer Services einfach verschiedene Daten benötigen. Manchmal reicht hier schon Ihre E-Mail-Adresse, doch wenn Sie etwa ein Produkt oder eine Dienstleistung erwerben, benötigen wir auch Daten wie beispielsweise Name, Adresse, Bankdaten oder Vertragsdaten. Wir nutzen die Daten in weiterer Folge auch für Marketing- und Vertriebsoptimierungen, damit wir insgesamt unser Service für unsere Kunden verbessern können. Ein weiterer wichtiger Punkt ist auch unser Kundenservice, der uns stets sehr am Herzen liegt. Wir wollen, dass Sie mit Frage zu unseren Angeboten jederzeit zu uns kommen können und dafür brauchen wir zumindest Ihre E-Mail-Adresse.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Welche Daten genau gespeichert werden, kann an dieser Stelle nur anhand Kategorien wiedergegeben werden. Dies hängt nämlich stets davon ab, welche Leistungen Sie von uns beziehen. In manchen Fällen geben Sie uns lediglich Ihre E-Mail-Adresse, damit wir beispielsweise mit Ihnen in Kontakt treten können oder wir Ihre Fragen beantworten können. In anderen Fällen erwerben Sie ein Produkt oder eine Dienstleistung bei uns und dafür benötigen wir deutlich mehr Informationen, wie etwa Ihre Kontaktdaten, Zahlungsdaten und Vertragsdaten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und verarbeiten:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Name</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Kontaktadresse</li>
<li className="mt-6 text-lg leading-8 text-gray-600">E-Mail-Adresse</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Telefonnummer</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Geburtsdatum</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Zahlungsdaten (Rechnungen, Bankdaten, Zahlungshistorie etc.)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Vertragsdaten ( Laufzeit, Inhalt)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Metadaten (IP-Adresse, Geräte-Informationen)</li>
</ul>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sobald wir die Kundendaten zur Erfüllung unserer vertraglichen Pflichten und unserer Zwecke nicht mehr benötigen und die Daten auch nicht für mögliche Gewährleistungs- und Haftungspflichten nötig sind, löschen wir die entsprechenden Kundendaten. Das ist zum Beispiel der Fall, wenn ein geschäftlicher Vertrag endet. Danach beträgt die Verjährungsfrist in der Regel 3 Jahre, wobei längere Fristen im Einzelfall möglich sind. Wir halten uns natürlich auch an die gesetzlichen Aufbewahrungspflichten. Ihre Kundendaten werden ganz sicher nicht an Dritte weitergegeben, wenn Sie dazu nicht explizit eine Einwilligung erteilt haben.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)  und in speziellen Fällen (z. B. bei medizinischen Leistungen) Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien).</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gemäß Art. 9 Abs. 2 lit. c. DSGVO. Für Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, für die medizinische Diagnostik, für die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gemäß Art. 9 Abs. 2 lit. h. DSGVO. Wenn Sie freiwillig Daten der besonderen Kategorien mitteilen, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="registrierung" >Registrierung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Registrierung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: <span  className="font-medium">Alle Personen, die sich registrieren, ein Konto anlegen, sich anmelden und das Konto nutzen.<br />
&#x1f4d3; Verarbeitete Daten: E-Mail-Adresse, Name, Passwort und weitere Daten, die im Zuge der Registrierung, Anmeldung und Kontonutzung erhoben werden.<br />
</span>&#x1f91d; Zweck: <span  className="font-medium">Zurverfügungstellung unserer Dienstleistungen. Kommunikation mit Kunden in Zusammenhang mit den Dienstleistungen.</span>
<br />
&#x1f4c5; Speicherdauer: S<span  className="font-medium">olange das mit den Texten verbundene Firmenkonto besteht und danach i.d.R. 3 Jahre.<br />
</span>&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Wenn Sie sich bei uns registrieren, kann es zur Verarbeitung personenbezogener Daten kommen, sofern Sie Daten mit Personenbezug eingeben bzw. Daten wie die IP-Adresse im Zuge der Verarbeitung erfasst werden. Was wir mit dem doch recht sperrigen Begriff “personenbezogene Daten” meinen, können Sie weiter unten nachlesen.</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Bitte geben Sie nur solche Daten ein, die wir für die Registrierung benötigen und für die Sie die Freigabe eines Dritten haben, falls Sie die Registrierung im Namen eines Dritten durchführen. Verwenden Sie nach Möglichkeit ein sicheres Passwort, welches Sie sonst nirgends verwenden und eine E-Mail-Adresse, die Sie regelmäßig abrufen.</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Im Folgenden informieren wir Sie über die genaue Art der Datenverarbeitung, denn Sie sollen sich bei uns wohl fühlen!</span>
</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist eine Registrierung?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Bei einer Registrierung nehmen wir bestimmte Daten von Ihnen entgegen und ermöglichen es Ihnen sich später bei uns einfach online anzumelden und Ihr Konto bei uns zu verwenden. Ein Konto bei uns hat den Vorteil, dass Sie nicht jedes Mal alles erneut eingeben müssen. Spart Zeit, Mühe und verhindert letztendlich Fehler bei der Erbringung unserer Dienstleistungen.</span>
</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verarbeiten wir personenbezogene Daten?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Kurz gesagt verarbeiten wir personenbezogene Daten, um die Erstellung und Nutzung eines Kontos bei uns zu ermöglichen.</span>
<span  className="font-medium">
<br />
</span>
<span  className="font-medium">Würden wir das nicht tun, müssten Sie jedes Mal alle Daten eingeben, auf eine Freigabe von uns warten und alles noch einmal eingeben. Das fänden wir und viele, viele Kunden nicht so gut. Wie würden Sie das finden?</span>
</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Alle Daten, die Sie im Zuge der Registrierung angegeben haben, bei der Anmeldung eingeben oder im Rahmen der Verwaltung Ihrer Daten im Konto eingeben.</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Bei der Registrierung verarbeiten wir folgende Arten von Daten: </span>
</p>
<ul className="list-disc">
<li  className="font-medium">
<span  className="font-medium">Vorname</span>
</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Nachname</li>
<li  className="font-medium">
<span  className="font-medium">E-Mail-Adresse</span>
</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Firmenname</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Straße + Hausnummer</li>
<li  className="font-medium">Wohnort</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Postleitzahl</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Land</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Bei der Anmeldung verarbeiten wir die Daten, die Sie bei der Anmeldung eingeben wie zum Beispiel Benutzername und Passwort und im Hintergrund erfasste Daten wie Geräteinformationen und IP-Adressen.</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Bei der Kontonutzung verarbeiten wir Daten, die Sie während der Kontonutzung eingeben und welche im Rahmen der Nutzung unserer Dienstleistungen erstellt werden.</span>
</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Speicherdauer</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir speichern die eingegebenen Daten zumindest für die Zeit, solange das mit den Daten verknüpfte Konto bei uns besteht und verwendet wird, solange vertragliche Verpflichtungen zwischen uns bestehen und, wenn der Vertrag endet, bis die jeweiligen Ansprüche daraus verjährt sind. Darüber hinaus speichern wir Ihre Daten solange und soweit wir gesetzlichen Verpflichtungen zur Speicherung unterliegen. Danach bewahren wir zum Vertrag gehörige Buchungsbelege (Rechnungen, Vertragsurkunden, Kontoauszüge u.a.) sowie sonstige relevante Geschäftsunterlagen für die gesetzlich vorgeschriebene Dauer (i.d.R. einige Jahre) auf.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Sie haben sich registriert, Daten eingegeben und möchten die Verarbeitung widerrufen? Kein Problem. Wie Sie oben lesen können, bestehen die Rechte laut Datenschutz-Grundverordnung auch bei und nach der Registrierung, Anmeldung oder dem Konto bei uns. Kontaktieren Sie den weiter oben stehenden Verantwortlichen für Datenschutz, um Ihre Rechte wahrzunehmen. Sollten Sie bereits ein Konto bei uns haben, können Sie Ihre Daten und Texte ganz einfach im Konto einsehen bzw. verwalten.</span>
</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Mit Durchführung des Registrierungsvorgangs treten Sie vorvertraglich an uns heran, um einen Nutzungsvertrag über unsere Plattform zu schließen (wenn auch nicht automatisch eine Zahlungspflicht entsteht). S</span>ie investieren Zeit, um Daten einzugeben und sich zu registrieren und wir bieten Ihnen unsere Dienstleistungen nach Anmeldung in unserem System und die Einsicht in Ihr Kundenkonto. Außerdem kommen wir unseren vertraglichen Verpflichtungen nach. Schließlich müssen wir registrierte Nutzer bei wichtigen Änderungen per E-Mail am Laufenden halten. Damit trifft Art. 6 Abs. 1 lit. b DSGVO (Durchführung vorvertraglicher Maßnahmen, Erfüllung eines Vertrags) zu.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Gegebenenfalls holen darüber hinaus auch Ihre Einwilligung ein, z.B. wenn Sie freiwillig mehr als die unbedingt notwendigen Daten angeben oder wir Ihnen Werbung senden dürfen. Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) trifft somit zu.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben außerdem ein berechtigtes Interesse, zu wissen, mit wem wir es zu tun haben, um in bestimmten Fällen in Kontakt zu treten. Außerdem müssen wir wissen wer unsere Dienstleistungen in Anspruch nimmt und ob sie so verwendet werden, wie es unsere Nutzungsbedingungen vorgeben, es trifft also Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen) zu.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Hinweis: folgende Abschnitte sind von Usern (je nach Bedarf) anzuhaken:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Registrierung mit Klarnamen</strong>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Da wir im geschäftlichen Betrieb wissen müssen, mit wem wir es zu tun haben, ist die Registrierung nur mit Ihrem richtigen Namen (Klarnamen) möglich und nicht mit Pseudonymen.</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Registrierung mit Pseudonymen</strong>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Bei der Registrierung können Pseudonyme verwendet werden, das heißt Sie müssen sich bei uns nicht mit Ihrem richtigen Namen registrieren. Damit ist sichergestellt, dass Ihr Name nicht von uns verarbeitet werden kann. </span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Speicherung der IP-Adresse</strong>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Im Zuge der Registrierung, Anmeldung und Kontonutzung speichern wir aus Sicherheitsgründen die IP-Adresse im Hintergrund, um die rechtmäßige Nutzung feststellen zu können.</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Öffentliche Profil</strong>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Die Nutzerprofile sind öffentlich sichtbar, d.h. man kann Teile des Profils auch ohne Angabe von Benutzername und Passwort im Internet sehen.</span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >2-Faktor-Authentifizierung (2FA)</strong>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  className="font-medium">Eine Zwei-Faktor-Authentifizierung (2FA) bietet zusätzlich Sicherheit bei der Anmeldung, da sie verhindert, dass man sich z.B. ohne Smartphone anmeldet. Diese technische Maßnahme zur Absicherung Ihres Kontos schützt Sie also vor dem Verlust von Daten oder unzulässigen Zugriffen auch wenn Benutzername und Passwort bekannt wären. </span>
<span  className="font-medium">Welches 2FA zum Einsatz kommt, erfahren Sie bei der Registrierung, Anmeldung und im Konto selbst.</span>
</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="webhosting-einleitung" >Webhosting Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Webhosting Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
&#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
&#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Webhosting?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verarbeiten wir personenbezogene Daten?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Zwecke der Datenverarbeitung sind:</p>
<ol>
<li className="mt-6 text-lg leading-8 text-gray-600">Professionelles Hosting der Website und Absicherung des Betriebs</li>
<li className="mt-6 text-lg leading-8 text-gray-600">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
</ol>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Browser und Browserversion (z. B. Chrome 87)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">das verwendete Betriebssystem (z. B. Windows 10)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a  href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank" rel="noreferrer" rel="follow noopener">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Datum und Uhrzeit</li>
<li className="mt-6 text-lg leading-8 text-gray-600">in Dateien, den sogenannten Webserver-Logfiles</li>
</ul>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange werden Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="firebase-cloud-datenschutzerklaerung" >Firebase Cloud Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website Firebase, unter anderem ein Webhosting- und Cloud-Dienst. Dienstanbieter ist das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google hält einen Vertrag über die Auftragsverarbeitung gemäß Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage für unsere Kundenbeziehung zu Google fungiert. Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln. Hier finden Sie die Auftragsverarbeitungsbedingungen: <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Firebase verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://policies.google.com/privacy?hl=en-US?tid=312492666" target="_blank" rel="noreferrer noopener">https://policies.google.com/privacy?hl=en-US</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="web-analytics-einleitung" >Web Analytics Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Web Analytics Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
&#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Web Analytics Tool.<br />
&#x1f4c5; Speicherdauer: abhängig vom eingesetzten Web-Analytics-Tool<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Web Analytics?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen über das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verfügung gestellt. Zusätzlich bieten die meisten Tools verschiedene Testmöglichkeiten an. So können wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Dafür zeigen wir Ihnen für einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. Für solche Testverfahren, wie auch für andere Analytics-Verfahren, können auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum betreiben wir Web Analytics?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen für unsere Branche das beste Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer Website rundum wohlfühlen. Mit Hilfe von Webanalyse-Tools können wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann entsprechend unser Webangebot für Sie und uns verbessern. So können wir beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an Ihre Bedürfnisse, Interessen und Wünsche anzupassen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Welche Daten genau gespeichert werden, hängt natürlich von den verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Gerät (PC, Tablet, Smartphone usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren, dass auch Standortdaten erhoben werden dürfen, können auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Zudem wird auch Ihre IP-Adresse gespeichert. Gemäß der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und gekürzter Form) gespeichert. Für den Zweck der Tests, der Webanalyse und der Weboptimierung werden grundsätzlich keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So können Sie als Person nicht identifiziert werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel für client-basiertes Webtracking mit Java-Script-Code.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<img role="img" src="https://www.adsimple.at/wp-content/uploads/2021/04/google-analytics-dataflow.svg" alt="Schematischer Datenfluss bei Google Analytics" width="100%" />
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wie lange die jeweiligen Daten gespeichert werden, hängt immer vom Anbieter ab. Manche Cookies speichern Daten nur für ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies können Daten über mehrere Jahre speichern.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">
<span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Web-Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist <strong >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung erteilt haben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das Lesen unserer allgemeinen Datenschutzerklärung zu Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="facebook-conversions-api-datenschutzerklaerung" >Facebook Conversions API Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website Facebook Conversions API, ein serverseitiges Event-Trackingtool. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. Für den europäischen Raum ist das Unternehmen Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Facebook, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Facebook Datenverarbeitungsbedingung, welche den Standardvertragsklauseln entsprechen, finden Sie unter <a  href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Facebook Conversions API verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="firebase-datenschutzerklaerung" >Firebase Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website Firebase, ein Analyse- und Monitoring-Tool. Dienstanbieter ist das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Firebase verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer" rel="follow noopener">https://policies.google.com/privacy?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-analytics-datenschutzerklaerung" >Google Analytics Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Google Analytics Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
&#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: abhängig von den verwendeten Properties<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Google Analytics?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking-Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unser Service begeistern können.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
</ul>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Google Analytics auf unserer Website?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden von Google Analytics gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Um mit Google Analytics unsere Website analysieren zu können, muss eine Property-ID in den Tracking-Code eingefügt werden. Die Daten werden dann in der entsprechenden Property gespeichert. Für jede neu angelegte Property ist die Google Analytics 4-Property standardmäßig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Folgende Cookies werden von Google Analytics verwendet:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _ga<br />
<strong >Wert: </strong>2.1326744211.152312492666-5<br />
<strong >Verwendungszweck:</strong> Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.<br />
<strong >Ablaufdatum:</strong> nach 2 Jahren</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _gid<br />
<strong >Wert: </strong>2.1687193234.152312492666-1<br />
<strong >Verwendungszweck:</strong> Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br />
<strong >Ablaufdatum:</strong> nach 24 Stunden</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br />
<strong >Wert:</strong> 1<br />
<strong >Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br />
<strong >Ablaufdatum: </strong>nach 1 Minute</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> AMP_TOKEN<br />
<strong >Wert:</strong> keine Angaben<br />
<strong >Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.<br />
<strong >Ablaufdatum:</strong> nach 30 Sekunden bis zu einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> __utma<br />
<strong >Wert: </strong>1564498958.1564498958.1564498958.1<br />
<strong >Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
<strong >Ablaufdatum:</strong> nach 2 Jahren</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> __utmt<br />
<strong >Wert:</strong> 1<br />
<strong >Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate verwendet.<br />
<strong >Ablaufdatum:</strong> nach 10 Minuten</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> __utmb<br />
<strong >Wert: </strong>3.10.1564498958<br />
<strong >Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br />
<strong >Ablaufdatum:</strong> nach 30 Minuten</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> __utmc<br />
<strong >Wert:</strong> 167421564<br />
<strong >Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schließen.<br />
<strong >Ablaufdatum:</strong> Nach Schließung des Browsers</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> __utmz<br />
<strong >Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br />
<strong >Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br />
<strong >Ablaufdatum:</strong> nach 6 Monaten</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> __utmv<br />
<strong >Wert:</strong> keine Angabe<br />
<strong >Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br />
<strong >Ablaufdatum:</strong> nach 2 Jahren</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Anmerkung:</strong> Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Heatmaps:</strong> Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >IP-Adresse:</strong> Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich ist.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Standort:</strong> Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Technische Informationen:</strong> Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z. B. wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange und wo werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Google hat ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a  href="https://www.google.com/about/datacenters/locations/?hl=de" target="_blank" rel="noreferrer" rel="follow noopener">https://www.google.com/about/datacenters/locations/?hl=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Aufbewahrungsdauer der Daten hängt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. Für andere sogenannte Ereignisdaten haben wir die Möglichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu wählen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Löschung nach 14 Monaten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Löschung nach 26 Monaten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Löschung nach 38 Monaten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Löschung nach 50 Monaten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Keine automatische Löschung</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">Zusätzlich gibt es auch die Option, dass Daten erst dann gelöscht werden, wenn Sie innerhalb des von uns gewählten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zurückgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter <a  href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noreferrer" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Falls Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten wollen, finden Sie unter dem Abschnitt „Cookies“ die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, können Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage dafür ist <strong >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a  href="https://marketingplatform.google.com/about/analytics/terms/de/">https://marketingplatform.google.com/about/analytics/terms/de/</a> und <a  href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank" rel="noreferrer" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="auftragsverarbeitungsvertrag-avv-google-analytics" >Auftragsverarbeitungsvertrag (AVV) Google Analytics</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, können Sie in unserem allgemeinen Abschnitt „Auftragsverarbeitungsvertrag (AVV)“ nachlesen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird geklärt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zu den Auftragsdatenverarbeitungsbedingungen finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>
</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-analytics-berichte-zu-demografischen-merkmalen-und-interessen" >Google Analytics Berichte zu demografischen Merkmalen und Interessen</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir uns &#8211; ohne diese Daten einzelnen Personen zuordnen zu können &#8211; ein besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen erfahren Sie auf <a  href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad">https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter “Einstellungen für Werbung” auf <a  href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> per Checkbox beenden.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-analytics-im-einwilligungsmodus" >Google Analytics im Einwilligungsmodus</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Abhängig von Ihrer Einwilligung werden im sogenannten Einwilligungsmodus (bzw. „Consent Mode“) personenbezogene Daten von Ihnen durch Google Analytics verarbeitet. Sie können wählen, ob Sie Google-Analytics-Cookies zustimmen oder nicht. Damit wählen Sie auch, welche Daten Google Analytics von Ihnen verarbeitet darf. Diese erhobenen Daten werden hauptsächlich dafür verwendet, Messungen über das Userverhalten auf der Website durchzuführen, zielgerichtete Werbung auszuspielen und uns Web-Analyseberichte zu liefern. In der Regel willigen Sie der Datenverarbeitung durch Google über ein Cookie-Consent-Tool ein. Wenn Sie der Datenverarbeitung nicht einwilligen, werden nur aggregierte Daten erfasst und verarbeitet. Das bedeutet, Daten können einzelnen Usern nicht zugeordnet werden und es entsteht somit kein Userprofil von Ihnen. Sie können auch nur der statistischen Messung zustimmen. Dabei werden keine personenbezogenen Daten verarbeitet und folglich nicht für Werbungen oder Werbemesserfolge verwendet.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-analytics-ip-anonymisierung" >Google Analytics IP-Anonymisierung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn diese eine Speicherung der vollständigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr Informationen zur IP-Anonymisierung finden Sie auf <a  href="https://support.google.com/analytics/answer/2763052?hl=de" target="_blank" rel="noreferrer noopener">https://support.google.com/analytics/answer/2763052?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-analytics-ohne-cookies" >Google Analytics ohne Cookies</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir nutzen auf unserer Website zwar Google Analytics (kurz GA), allerdings ohne Cookies in Ihrem Browser zu setzen. Was Cookies sind, haben wir bereits weiter oben erklärt, hoffentlich sind die Ausführungen noch im Gedächtnis. Nur kurz und konkret auf GA bezogen: Durch Cookies werden für GA hilfreiche Daten in Ihrem Browser auf Ihrem Gerät gespeichert. Durch den Entfall der Nutzung von Cookies werden gerade keine personenbezogenen Daten in solchen Cookies gespeichert, die ein Nutzerprofil begründen werden. Google Analytics kann zwar diverse Messungen und Webanalysen durchführen, jedoch werden die dafür erhobenen Daten lediglich auf den Google-Servern gespeichert und Ihre Privatsphäre wird deutlich stärker respektiert und geschützt.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-optimize-datenschutzerklaerung" >Google Optimize Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website Google Optimize, ein Website-Optimierungstool. Dienstanbieter ist das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Google Optimize verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer" rel="follow noopener">https://policies.google.com/privacy?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="auftragsverarbeitungsvertrag-avv-google-optimize" >Auftragsverarbeitungsvertrag (AVV) Google Optimize</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, können Sie in unserem allgemeinen Abschnitt „Auftragsverarbeitungsvertrag (AVV)“ nachlesen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird geklärt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zu den Auftragsdatenverarbeitungsbedingungen finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>
</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-remarketing-datenschutzerklaerung" >Google Remarketing Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden für unserer Website auch Google Remarketing, ein Werbe-Analyse-Tool. Dienstanbieter ist das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google hält einen Vertrag über die Auftragsverarbeitung gemäß Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage für unsere Kundenbeziehung zu Google fungiert. Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln. Hier finden Sie die Auftragsverarbeitungsbedingungen: <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Google Remarketing verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer" rel="follow noopener">https://policies.google.com/privacy?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="e-mail-marketing-einleitung" >E-Mail-Marketing Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >E-Mail-Marketing Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Newsletter-Abonnenten<br />
&#x1f91d; Zweck: Direktwerbung per E-Mail, Benachrichtigung über systemrelevante Ereignisse<br />
&#x1f4d3; Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens die E-Mail-Adresse. Mehr Details dazu finden Sie beim jeweils eingesetzten E-Mail-Marketing-Tool.<br />
&#x1f4c5; Speicherdauer: Dauer des Bestehens des Abonnements<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist E-Mail-Marketing?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die Möglichkeit des E-Mail-Marketings. Dabei werden, sofern Sie dem Empfang unserer E-Mails bzw. Newsletter zugestimmt haben, auch Daten von Ihnen verarbeitet und gespeichert. E-Mail-Marketing ist ein Teilbereich des Online-Marketings. Dabei werden Neuigkeiten oder allgemeine Informationen über ein Unternehmen, Produkte oder Dienstleistungen per E-Mail an eine bestimmte Gruppe an Menschen, die sich dafür interessieren, gesendet.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen wollen, müssen Sie sich im Normalfall einfach nur mit Ihrer E-Mail-Adresse anmelden. Dafür füllen Sie ein Online-Formular aus und senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa um Ihre Anrede und Ihren Namen bitten, damit wir Sie auch persönlich anschreiben können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Grundsätzlich funktioniert das Anmelden zu Newslettern mit Hilfe des sogenannten „Double-Opt-In-Verfahrens“. Nachdem Sie sich für unseren Newsletter auf unserer Website angemeldet haben, bekommen Sie eine E-Mail, über die Sie die Newsletter-Anmeldung bestätigen. So wird sichergestellt, dass Ihnen die E-Mail-Adresse gehört und sich niemand mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns verwendetes Benachrichtigungs-Tool protokolliert jede einzelne Anmeldung. Dies ist nötig, damit wir den rechtlich korrekten Anmeldevorgang auch nachweisen können. Dabei wird in der Regel der Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebestätigung und Ihre IP-Adresse gespeichert. Zusätzlich wird auch protokolliert, wenn Sie Änderungen Ihrer gespeicherten Daten vornehmen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum nutzen wir E-Mail-Marketing?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir wollen natürlich mit Ihnen in Kontakt bleiben und Ihnen stets die wichtigsten Neuigkeiten über unser Unternehmen präsentieren. Dafür nutzen wir unter anderem E-Mail-Marketing &#8211; oft auch nur &#8220;Newsletter&#8221; bezeichnet &#8211; als wesentlichen Bestandteil unseres Online-Marketings. Sofern Sie sich damit einverstanden erklären oder es gesetzlich erlaubt ist, schicken wir Ihnen Newsletter, System-E-Mails oder andere Benachrichtigungen per E-Mail. Wenn wir im folgenden Text den Begriff „Newsletter“ verwenden, meinen wir damit hauptsächlich regelmäßig versandte E-Mails. Natürlich wollen wir Sie mit unseren Newsletter in keiner Weise belästigen. Darum sind wir wirklich stets bemüht, nur relevante und interessante Inhalte zu bieten. So erfahren Sie etwa mehr über unser Unternehmen, unsere Leistungen oder Produkte. Da wir unsere Angebote auch immer verbessern, erfahren Sie über unseren Newsletter auch immer, wenn es Neuigkeiten gibt oder wir gerade spezielle, lukrative Aktionen anbieten. Sofern wir einen Dienstleister, der ein professionelles Versand-Tool anbietet, für unser E-Mail-Marketing beauftragen, machen wir das, um Ihnen schnelle und sichere Newsletter bieten zu können. Zweck unseres E-Mail-Marketings ist grundsätzlich, Sie über neue Angebote zu informieren und auch unseren unternehmerischen Zielen näher zu kommen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie über unsere Website Abonnent unseres Newsletters werden, bestätigen Sie per E-Mail die Mitgliedschaft in einer E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse können auch Ihre Anrede, Ihr Name, Ihre Adresse und Ihre Telefonnummer gespeichert werden. Allerdings nur, wenn Sie dieser Datenspeicherungen zustimmen. Die als solche markierten Daten sind notwendig, damit Sie an dem angebotenen Dienst teilnehmen können. Die Angabe ist freiwillig, die Nichtangabe führt jedoch dazu, dass Sie den Dienst nicht nutzen können. Zusätzlich können etwa auch Informationen zu Ihrem Gerät oder zu Ihren bevorzugten Inhalten auf unserer Website gespeichert werden. Mehr zur Speicherung von Daten, wenn Sie eine Website besuchen, finden Sie im Abschnitt &#8220;Automatische Datenspeicherung&#8221;. Ihre Einwilligungserklärung zeichnen wir auf, damit wir stets nachweisen können, dass dieser unseren Gesetzen entspricht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Dauer der Datenverarbeitung</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler austragen, dürfen wir Ihre Adresse bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, damit wir Ihre damalige Einwilligung noch nachweisen können. Verarbeiten dürfen wir diese Daten nur, wenn wir uns gegen etwaige Ansprüche wehren müssen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie allerdings bestätigen, dass Sie uns die Einwilligung zur Newsletter-Anmeldung gegeben haben, können Sie jederzeit einen individuellen Löschantrag stellen. Widersprechen Sie der Einwilligung dauerhaft, behalten wir uns das Recht vor, Ihre E-Mail-Adresse in einer Sperrliste zu speichern. Solange Sie freiwillig unseren Newsletter abonniert haben, solange behalten wir selbstverständlich auch Ihre E-Mail-Adresse.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben jederzeit die Möglichkeit Ihre Newsletter-Anmeldung zu kündigen. Dafür müssen Sie lediglich Ihre Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt am Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu kündigen. Wenn der Link im Newsletter wirklich nicht zu finden ist, kontaktieren Sie uns bitte per Mail und wir werden Ihr Newsletter-Abo unverzüglich kündigen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer Einwilligung (Artikel 6 Abs. 1 lit. a DSGVO). Das heißt, wir dürfen Ihnen nur dann einen Newsletter schicken, wenn Sie sich zuvor aktiv dafür angemeldet haben. Gegebenenfalls können wir Ihnen auch Werbenachrichten senden, sofern Sie unser Kunde geworden sind und der Verwendung Ihrer E-Mailadresse für Direktwerbung nicht widersprochen haben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen E-Mail-Marketing Diensten und wie diese personenbezogene Daten verarbeiten, erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="sendgrid-datenschutzerklaerung" >SendGrid Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery.&quot;}" data-sheets-userformat="{&quot;2&quot;:4865,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial, sans-serif&quot;}">Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery. Dienstanbieter ist das amerikanische Unternehmen Twilio Inc., 889 Winslow St, Redwood City, California 94063, USA. </span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery.&quot;}" data-sheets-userformat="{&quot;2&quot;:4865,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial, sans-serif&quot;}">SendGrid</span> verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet <span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery.&quot;}" data-sheets-userformat="{&quot;2&quot;:4865,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial, sans-serif&quot;}">SendGrid</span> sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich <span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery.&quot;}" data-sheets-userformat="{&quot;2&quot;:4865,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial, sans-serif&quot;}">SendGrid</span>, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Datenverarbeitungsbedingungen (Data Processing Addendum), welche den Standardvertragsklauseln entsprechen, finden Sie unter <a  href="https://www.twilio.com/legal/data-protection-addendum" target="_blank" rel="noreferrer" rel="follow noopener">https://www.twilio.com/legal/data-protection-addendum</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wir verwenden auf unserer Website SendGrid, ein Dienst zur E-Mail Delivery.&quot;}" data-sheets-userformat="{&quot;2&quot;:4865,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0,&quot;15&quot;:&quot;Arial, sans-serif&quot;}">Mehr über die Daten, die durch die Verwendung von SendGrid verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://www.twilio.com/legal/privacy" target="_blank" rel="noreferrer noopener">https://www.twilio.com/legal/privacy.</a> </span>
</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="social-media-einleitung" >Social Media Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Social Media Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br />
&#x1f4c5; Speicherdauer: abhängig von den verwendeten Social-Media-Plattformen<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Social Media?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Zusätzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet werden, damit wir gezielt User, die sich für uns interessieren, über die sozialen Netzwerke ansprechen können. Darüber hinaus können auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen können.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum nutzen wir Social Media?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten können wir unsere Produkte und Dienstleistungen Interessenten näherbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchführen zu können. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten auf einer Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Social-Media-Kanäle, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte geben bzw. Änderungen vornehmen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">
<span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gelöscht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong >(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong >(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen Social-Media-Plattformen erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="facebook-datenschutzerklaerung" >Facebook Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Facebook Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Kundendaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: bis die Daten für Facebooks Zwecke nicht mehr nützlich sind<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was sind Facebook-Tools?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website ausgewählte Tools von Facebook. Facebook ist ein Social Media Network des Unternehmens Meta Platforms Inc. bzw. für den europäischen Raum des Unternehmens Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich für unsere Produkte und Dienstleistungen interessieren, das bestmögliche Angebot bieten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn über unsere eingebetteten Facebook-Elemente oder über unsere Facebook-Seite (Fanpage) Daten von Ihnen erhoben und weitergeleitet werden, sind sowohl wir als auch Facebook Irland Ltd. dafür verantwortlich. Für die weitere Verarbeitung dieser Daten trägt Facebook allein die Verantwortung. Unsere gemeinsamen Verpflichtungen wurden auch in einer öffentlich zugänglichen Vereinbarung unter <a  href="https://www.facebook.com/legal/controller_addendum">https://www.facebook.com/legal/controller_addendum</a> verankert. Darin ist etwa festgehalten, dass wir Sie klar über den Einsatz der Facebook-Tools auf unserer Seite informieren müssen. Weiters sind wir auch dafür verantwortlich, dass die Tools datenschutzrechtlich sicher in unsere Website eingebunden sind. Facebook ist hingegen beispielsweise für die Datensicherheit der Facebook-Produkte verantwortlich. Bei etwaigen Fragen zur Datenerhebung und Datenverarbeitung durch Facebook können Sie sich direkt an das Unternehmen wenden. Wenn Sie die Frage an uns richten, sind wir dazu verpflichtet diese an Facebook weiterleiten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Im Folgenden geben wir einen Überblick über die verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Neben vielen anderen Produkten bietet Facebook auch die sogenannten &#8220;Facebook Business Tools&#8221; an. Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Facebook-Pixel</li>
<li className="mt-6 text-lg leading-8 text-gray-600">soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Facebook Login</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Account Kit</li>
<li className="mt-6 text-lg leading-8 text-gray-600">APIs (Programmierschnittstelle)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">SDKs (Sammlung von Programmierwerkzeugen)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Plattform-Integrationen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Plugins</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Codes</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Spezifikationen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Dokumentationen</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Technologien und Dienstleistungen</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">Durch diese Tools erweitert Facebook Dienstleistungen und hat die Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook zu erhalten.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Facebook-Tools auf unserer Website?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings Informationen über die Wünsche und Bedürfnisse der Menschen. So werden dem Unternehmen Informationen über das Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere User-Daten und kann interessierten Menschen die passende Werbung über unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“. Diese werden auch für Messungs- und Analysedienste verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Sie unsere Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden von Facebook-Tools gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Facebook verwendet diese Informationen, um die Daten mit den Daten, die es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine Zeichenkette transformiert wird. Dies dient auch der Verschlüsselung von Daten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit Kontaktdaten verbunden werden. Dadurch kann Facebook bessere personalisierte Werbung anbieten. Nach dem bereits erwähnten Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten nützt Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecke. Viele dieser Daten werden über Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die zum Speichern von Daten bzw. Informationen in Browsern verwendet werden. Je nach verwendeten Tools und abhängig davon, ob Sie Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine Informationen über die Verwendung von Facebook-Cookies erfahren Sie auch auf <a  href="https://www.facebook.com/policies/cookies?tid=312492666">https://www.facebook.com/policies/cookies</a>.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange und wo werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat auf der ganzen Welt Server verteilt, wo seine Daten gespeichert werden. Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden, innerhalb von 48 Stunden gelöscht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr Facebook-Konto vollständig löschen. Und so funktioniert das Löschen Ihres Facebook-Kontos:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">2) Anschließend klicken Sie in der linken Spalte auf „Deine Facebook-Informationen“.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">3) Nun klicken Sie &#8220;Deaktivierung und Löschung&#8221;.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und Konto löschen“</p>
<p className="mt-6 text-lg leading-8 text-gray-600">5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf „Konto löschen“</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Speicherung der Daten, die Facebook über unsere Seite erhält, erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Facebook-Tools verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong >(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong >(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien von Facebook anzusehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Facebook, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Facebook Datenverarbeitungsbedingung, welche den Standardvertragsklauseln entsprechen, finden Sie unter <a  href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir hoffen, wir haben Ihnen die wichtigsten Informationen über die Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht. Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten verwendet, empfehlen wir Ihnen die Datenrichtlinien auf <a  href="https://www.facebook.com/about/privacy/update">https://www.facebook.com/about/privacy/update</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="facebook-fanpage-datenschutzerklaerung" >Facebook Fanpage Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben für unsere Website auch eine Facebook Fanpage. Dienstanbieter ist das amerikanische Unternehmen Meta Platforms Inc. Für den europäischen Raum ist das Unternehmen Meta Platforms Ireland Limited (4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Facebook, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Facebook Datenverarbeitungsbedingung, welche den Standardvertragsklauseln entspricht, finden Sie unter <a  href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Facebook verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="instagram-datenschutzerklaerung" >Instagram Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Instagram Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: bis Instagram die Daten für ihre Zwecke nicht mehr benötigt<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Instagram?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Meta Platforms Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen hinweg verarbeitet.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Meta Platforms Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Meta-Datenschutzrichtlinien selbst.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur anderen interessante Users folgen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Instagram auf unserer Website?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie nicht persönlich identifizieren.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden von Instagram gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese Kundendaten werden erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat, abgeglichen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder <a  href="https://www.instagram.com/?tid=312492666" target="_blank" rel="noreferrer" rel="noopener noreferrer">www.instagram.com</a> besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Diese Cookies wurden bei unserem Test verwendet:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name: </strong>csrftoken<br />
<strong >Wert: </strong>&#8220;&#8221;<br />
<strong >Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<br />
<strong >Ablaufdatum:</strong> nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name: </strong>mid<br />
<strong >Wert: </strong>&#8220;&#8221;<br />
<strong >Verwendungszweck: </strong>Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.<br />
<strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> fbsr_312492666124024<br />
<strong >Wert: </strong>keine Angaben<br />
<strong >Verwendungszweck: </strong>Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.<strong >
<br />
</strong>
<strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> rur<br />
<strong >Wert: </strong>ATN<br />
<strong >Verwendungszweck: </strong>Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.<br />
<strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> urlgen<br />
<strong >Wert: </strong>&#8220;&#123;&#8221;194.96.75.33&#8221;: 1901&#125;:1iEtYv:Y833k2_UjKvXgYe312492666&#8221;<br />
<strong >Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von Instagram.<br />
<strong >Ablaufdatum:</strong> nach Ende der Sitzung</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange und wo werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Und so funktioniert die Löschung des Instagram-Kontos:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht gelöscht.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer ein bisschen anders. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong >(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong >(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Instagram bzw. Facebook verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Facebook von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten Facebook, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch außerhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier: <a  href="https://germany.representation.ec.europa.eu/index_de" target="_blank" rel="noreferrer" rel="follow noopener">https://germany.representation.ec.europa.eu/index_de</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram näherzubringen. Auf <a  href="https://help.instagram.com/519522125107875">https://help.instagram.com/519522125107875</a>
<br />
können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="pinterest-datenschutzerklaerung" >Pinterest Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Pinterest Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät, Ihre IP-Adresse und Suchbegriffe.<br />
Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: bis Pinterest die Daten für ihre Zwecke nicht mehr benötigt<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Pinterest?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Seite Buttons und Widgets des Social Media Netzwerks Pinterest, der Firma Pinterest Inc., 808 Brannan Street, San Francisco, CA 94103, USA. Für den europäischen Raum ist das irische Unternehmen Pinterest Europe Ltd. (Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland) für alle datenschutzrelevanten Aspekte zuständig.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Pinterest ist ein soziales Netzwerk, das sich auf grafische Darstellungen bzw. Fotografien spezialisiert hat. Der Name setzt sich aus den beiden Wörtern „pin“ und „interest“ zusammen. Nutzer können sich über Pinterest über verschiedene Hobbies und Interessen austauschen und sich offen oder in definierten Gruppen die jeweiligen Profile mit Bildern ansehen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Pinterest?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Pinterest gibt es mittlerweile schon einige Jahre und immer noch zählt diese Social-Media-Plattform zu den meist besuchten und geschätzten Plattformen. Speziell für unsere Branche eignet sich Pinterest, weil die Plattform in erster Linie für schöne und interessante Bilder bekannt ist. Darum sind natürlich auch wir auf Pinterest vertreten und wollen unsere Inhalte auch abseits unserer Website entsprechend in Szene setzen. Die erhobenen Daten können auch für Werbezwecke verwendet werden, damit wir Werbebotschaften genau jenen Menschen zeigen können, die sich für unsere Dienstleistungen bzw. Produkte interessieren.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden von Pinterest verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Es können sogenannte Protokolldaten gespeichert werden. Dazu zählen Informationen zu Ihrem Browser, IP-Adresse, die Adresse unserer Website und die darauf vorgenommenen Aktivitäten (zum Beispiel, wenn Sie den Merken- oder Pin-Button klicken), Suchverläufe, Datum und Uhrzeit der Anfrage und Cookie- und Gerätedaten. Wenn Sie mit einer eingebetteten Pinterest-Funktion interagieren, können auch Cookies, die diverse Daten speichern, in Ihrem Browser gesetzt werden. Meist werden die oben genannten Protokolldaten, voreingestellte Spracheinstellungen und Clickstream-Daten in Cookies gespeichert. Unter Clickstream-Daten versteht Pinterest Informationen zu Ihrem Websiteverhalten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie selbst ein Pinterest-Konto haben und eingeloggt sind, können die Daten, die über unsere Seite erhoben werden, zu Ihrem Konto hinzugefügt werden und für Werbezwecke verwendet werden. Wenn Sie mit unseren eingebundenen Pinterest-Funktionen interagieren, werden Sie in der Regel auf die Pinterest-Seite weitergeleitet. Hier sehen Sie eine beispielhafte Auswahl an Cookies, die dann in Ihrem Browser gesetzt werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _auth<br />
<strong >Wert:</strong> 0<br />
<strong >Verwendungszweck: </strong>Das Cookie dient der Authentifizierung. Darin kann beispielsweise ein Wert wie Ihr &#8220;Benutzername&#8221; gespeichert werden. <strong >
<br />
</strong>
<strong >Ablaufdatum:</strong> nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _pinterest_referrer<br />
<strong >Wert:</strong> 1<br />
<strong >Verwendungszweck: </strong>Das Cookie speichert, dass Sie über unsere Website zu Pinterest gelangten. Es wird also die URL unserer Website gespeichert.<br />
<strong >Ablaufdatum:</strong> nach Sitzungsende</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _pinterest_sess<br />
<strong >Wert:</strong> …9HRHZvVE0rQlUxdG89<br />
<strong >Verwendungszweck: </strong>Das Cookie dient der Anmeldung bei Pinterest und enthält Nutzer-IDs, Authentifizierungs-Token und Zeitstempel.<strong >
<br />
</strong>
<strong >Ablaufdatum:</strong> nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> _routing_id<br />
<strong >Wert:</strong> &#8220;8d850ddd-4fb8-499c-961c-77efae9d4065312492666-8&#8221;<br />
<strong >Verwendungszweck: </strong>Das Cookie beinhaltet einen zugewiesenen Wert, der zur Identifizierung eines bestimmten Routing-Ziels verwendet wird.<strong >
<br />
</strong>
<strong >Ablaufdatum:</strong> nach einem Tag</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> cm_sub<br />
<strong >Wert:</strong> denied<br />
<strong >Verwendungszweck: </strong>Dieses Cookie speichert eine Benutzer-ID und den Zeitstempel.<strong >
<br />
</strong>
<strong >Ablaufdatum:</strong> nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> csrftoken<br />
<strong >Wert:</strong> 9e49145c82a93d34fd933b0fd8446165312492666-1<br />
<strong >Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<strong >
<br />
</strong>
<strong >Ablaufdatum:</strong> nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> sessionFunnelEventLogged<br />
<strong >Wert:</strong> 1<br />
<strong >Verwendungszweck: </strong>Zu diesem Cookie konnten wir noch keine näheren Informationen in Erfahrung bringen.<strong >
<br />
</strong>
<strong >Ablaufdatum:</strong> nach einem Tag</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange und wo werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Pinterest speichert grundsätzlich die erhobenen Daten so lange, bis diese für die Zwecke des Unternehmens nicht mehr gebraucht werden. Sobald die Datenaufbewahrung nicht mehr notwendig ist, um etwa auch rechtlichen Vorschriften gerecht zu werden, werden die Daten entweder gelöscht oder anonymisiert, damit Sie als Person nicht mehr identifiziert werden können. Die Daten können auch auf amerikanischen Servern gespeichert werden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie Pinterest zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Da bei eingebetteten Pinterest-Elementen Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong >(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong >(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen das Tool gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Pinterest verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Pinterest sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Pinterest, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de.</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr Informationen zu den Standardvertragsklauseln bei Pinterest finden Sie unter <a  href="https://policy.pinterest.com/de/privacy-policy#section-residents-of-the-eea" target="_blank" rel="noreferrer" rel="follow noopener">https://policy.pinterest.com/de/privacy-policy#section-residents-of-the-eea</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Pinterest näherzubringen. Auf <a  href="https://policy.pinterest.com/de/privacy-policy">https://policy.pinterest.com/de/privacy-policy</a> können Sie sich noch näher mit den Datenrichtlinien von Pinterest auseinandersetzen.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="blogs-und-publikationsmedien-einleitung" >Blogs und Publikationsmedien Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Blogs und Publikationsmedien Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung sowie Kommunikation zwischen Websitebesuchern, Sicherheitsmaßnahmen und Verwaltung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Kontaktdaten, IP-Adresse und veröffentlichte Inhalte.<br />
Mehr Details dazu finden Sie bei den eingesetzten Tools.<br />
&#x1f4c5; Speicherdauer: abhängig von den verwendeten Tools<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (Vertrag)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was sind Blogs und Publikationsmedien?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir nutzen auf unsere Website Blogs oder auch andere Kommunikationsmittel, mit denen wir einerseits mit Ihnen und andererseits Sie auch mit uns kommunizieren können. Dabei können auch Daten von Ihnen bei uns gespeichert und verarbeitet werden. Das kann notwendig sein, damit wir Inhalte entsprechend darstellen können, die Kommunikation funktioniert und die Sicherheit erhöht wird. In unserem Datenschutztext gehen wir allgemein darauf ein, welche Daten von Ihnen verarbeitet werden können. Exakte Angaben zur Datenverarbeitung hängen stets auch von den verwendeten Tools und Funktionen ab. In den Datenschutzhinweisen der einzelnen Anbieter finden Sie genaue Informationen über die Datenverarbeitung.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum nutzen wir Blogs und Publikationsmedien?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Unser größtes Anliegen mit unserer Website ist Ihnen interessante und spannende Inhalte zu bieten und gleichzeitig sind uns auch Ihre Meinungen und Inhalte wichtig. Darum wollen wir einen guten interaktiven Austausch zwischen uns und Ihnen schaffen. Mit diversen Blogs und Publikationsmöglichkeiten können wir genau das erreichen. Sie können beispielsweise Kommentare zu unseren Inhalten verfassen, andere Kommentare kommentieren oder auch in manchen Fällen selbst Beiträge verfassen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Welche Daten genau verarbeitet werden, hängt stets von den von uns verwendeten Kommunikationsfunktionen ab. Sehr oft werden IP-Adresse, Benutzername und der veröffentlichte Inhalt gespeichert. Das geschieht in erster Linie, um einen Sicherheitsschutz zu gewährleisten, Spam vorzubeugen und um gegen widerrechtliche Inhalte vorgehen zu können. Für die Datenspeicherung können auch Cookies verwendet werden. Das sind kleine Text-Dateien, die mit Informationen in Ihrem Browser abgespeichert werden. Näheres zu den erhobenen und gespeicherten Daten finden Sie in unseren einzelnen Abschnitten und in der Datenschutzerklärung des jeweiligen Anbieters.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Dauer der Datenverarbeitung</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichern Beitrags- und Kommentarfunktionen Daten, bis Sie die Datenspeicherung widerrufen. Generell werden personenbezogene Daten nur so lange, wie es für die Bereitstellung unserer Dienstleistungen unbedingt notwendig ist, gespeichert.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern von Kommunikations-Tools zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Da bei Publikationsmedien auch Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir setzen die Kommunikationsmittel hauptsächlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden, Geschäftspartnern und Besuchern ein. Soweit die Nutzung der Abwicklung von vertraglichen Beziehungen oder deren Anbahnung dient, ist Rechtsgrundlage ferner Art. 6 Abs. 1 S. 1 lit. b. DSGVO.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Kommentar- oder Nachrichtenfunktionen bedürfen Ihrer Einwilligung. Wenn und soweit Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Publikationsmedien verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO). Die meisten von uns verwendeten Kommunikationsfunktionen setzen Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen Tools erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="blogbeitraege-und-kommentarfunktionen-datenschutzerklaerung" >Blogbeiträge und Kommentarfunktionen Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Es gibt verschiedenen Online-Kommunikationsmittel, die wir auf unsere Website verwenden können. So nutzen wir beispielweise Blogbeiträge und Kommentarfunktionen. So haben Sie die Möglichkeit, Inhalte auch zu kommentieren bzw. Beiträge zu verfassen. Wenn Sie diese Funktion nutzen, kann aus Sicherheitsgründen etwa Ihre IP-Adresse gespeichert werden. So schützen wir uns vor widerrechtlichen Inhalten wie beispielsweise Beleidigungen, unerlaubte Werbung oder verbotene politische Propaganda. Um zu erkennen, ob es sich bei Kommentaren um Spam handelt, können wir auch auf Grundlage unseres berechtigten Interesses User-Angaben speichern und verarbeiten. Falls wir eine Umfrage starten, speichern wir auch für die Dauer der Umfrage Ihre IP-Adresse, damit wir sicher gehen können, dass alle Beteiligten auch wirklich nur einmal abstimmen. Es können zum Zweck der Speicherung auch Cookies zum Einsatz kommen. Alle Daten, die wir von Ihnen speichern (etwa Inhalte oder Informationen zu Ihrer Person), bleiben bis zu Ihrem Widerspruch gespeichert.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="online-marketing-einleitung" >Online-Marketing Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Online-Marketing Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br />
&#x1f4d3; Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Gerätedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse können verarbeitet werden. Mehr Details dazu finden Sie beim jeweils eingesetzten Online-Marketing-Tool.<br />
&#x1f4c5; Speicherdauer: abhängig von den eingesetzten Online-Marketing-Tools<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Online-Marketing?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Unter Online-Marketing bezeichnet man alle Maßnahmen, die online durchgeführt werden, um Marketingziele wie etwa die Steigerung der Markenbekanntheit oder einen Geschäftsabschluss zu erreichen. Weiters zielen unserer Online-Marketing-Maßnahmen darauf ab, Menschen auf unsere Website aufmerksam zu machen. Um unser Angebot vielen interessierten Menschen zeigen zu können, betreiben wir also Online-Marketing. Meistens handelt es sich dabei um Onlinewerbung, Content-Marketing oder Suchmaschinenoptimierung. Damit wir Online-Marketing effizient und zielgerecht einsetzen können, werden auch personenbezogene Daten gespeichert und verarbeitet. Die Daten helfen uns einerseits unsere Inhalte nur wirklich jenen Personen zu zeigen, die sich dafür auch interessieren und andererseits können wir den Werbeerfolg unserer Online-Marketing-Maßnahmen messen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum nutzen wir Online-Marketing-Tools?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir wollen jedem Menschen, der sich für unser Angebot interessiert, unsere Website zeigen. Uns ist bewusst, dass dies ohne bewusst gesetzte Maßnahmen nicht möglich ist. Darum machen wir Online-Marketing. Es gibt verschiedene Tools, die uns die Arbeit an unseren Online-Marketing-Maßnahmen erleichtern und zusätzlich über Daten stets Verbesserungsvorschläge liefern. So können wir unsere Kampagnen genauer an unsere Zielgruppe richten. Zweck dieser eingesetzten Online-Marketing-Tools ist also letztlich die Optimierung unseres Angebots.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Damit unser Online-Marketing funktioniert und der Erfolg der Maßnahmen gemessen werden kann, werden Userprofile erstellt und Daten beispielsweise in Cookies (das sind kleine Text-Dateien) gespeichert. Mit Hilfe dieser Daten können wir nicht nur Werbung in klassischer Hinsicht schalten, sondern auch direkt auf unserer Website, unsere Inhalte so darstellen, wie es Ihnen am liebsten ist. Dafür gibt es verschiedene Drittanbieter-Tools, die diese Funktionen anbieten und entsprechend auch Daten von Ihnen sammeln und speichern. In den benannten Cookies wird zum Beispiel gespeichert, welche Webseiten Sie auf unserer Website besucht haben, wie lange Sie diese Seiten angesehen haben, welche Links oder Buttons Sie klicken oder von welcher Website sie zu uns gekommen sind. Zusätzlich können auch technische Informationen gespeichert werden. Etwa Ihre IP-Adresse, welchen Browser Sie nutzen, von welchem Endgerät Sie unsere Website besuchen oder die Uhrzeit, wann Sie unserer Website aufgerufen haben und wann Sie sie wieder verlassen haben. Wenn Sie eingewilligt haben, dass wir auch Ihren Standort bestimmen dürfen, können wir auch diesen speichern und verarbeiten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Ihre IP-Adresse wird in pseudonymisierter Form (also gekürzt) gespeichert. Eindeutige Daten, die Sie als Person direkt identifizieren, wie etwa Name, Adresse oder E-Mail-Adresse, werden im Rahmen der Werbe- und Online-Marketing-Verfahren auch nur in pseudonymisierter Form gespeichert. Wir können Sie also nicht als Person identifizieren, sondern wir haben nur die pseudonymisierten, gespeicherten Informationen in den Userprofilen gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Cookies können unter Umständen auch auf anderen Websites, die mit denselben Werbetools arbeiten, eingesetzt, analysiert und für Werbezwecke verwendet werden. Die Daten können dann auch auf den Servern der Werbetools-Anbieter gespeichert werden.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">In Ausnahmefällen können auch eindeutige Daten (Namen, Mail-Adresse usw.) in den Userprofilen gespeichert werden. Zu dieser Speicherung kommt es etwa, wenn Sie Mitglied eines Social-Media-Kanals sind, dass wir für unsere Online-Marketing-Maßnahmen nutzen und das Netzwerk bereits früher eingegangene Daten mit dem Userprofil verbindet.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bei allen von uns verwendeten Werbetools, die Daten von Ihnen auf deren Servern speichert, erhalten wir immer nur zusammengefasste Informationen und nie Daten, die Sie als Einzelperson erkennbar machen. Die Daten zeigen lediglich, wie gut gesetzte Werbemaßnahmen funktionierten. Zum Beispiel sehen wir, welche Maßnahmen Sie oder andere User dazu bewogen haben, auf unsere Website zu kommen und dort eine Dienstleistung oder ein Produkt zu erwerben. Anhand der Analysen können wir unser Werbeangebot in Zukunft verbessern und noch genauer an die Bedürfnisse und Wünsche interessierter Personen anpassen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Dauer der Datenverarbeitung</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert. Manche Cookies werden bereits nach dem Verlassen der Website wieder gelöscht, anderen können über einige Jahre in Ihrem Browser gespeichert sein. In den jeweiligen Datenschutzerklärungen der einzelnen Anbieter erhalten Sie in der Regel genaue Informationen über die einzelnen Cookies, die der Anbieter verwendet.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Die Rechtmäßigkeit der Verarbeitung bis zum Widerruf bleibt unberührt.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Da bei Online-Marketing-Tools in der Regel Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Online-Marketing-Tools vorkommen kann, dar.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Von unserer Seite besteht zudem ein berechtigtes Interesse, Online-Marketing-Maßnahmen in anonymisierter Form zu messen, um mithilfe der gewonnenen Daten unser Angebot und unsere Maßnahmen zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen Online-Marketing-Tools erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-marketing-platform-frueher-doubleclick-datenschutzerklaerung" >Google Marketing Platform (früher: DoubleClick) Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website Google-Marketing-Platform-Produkte. Darunter befinden sich diverse Marketingtools wie etwa Data Studio, Surveys, Campaign Manager 360, Display &amp; Video 360 oder Search Ads 360. Dienstanbieter ist das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Google-Marketing-Platform-Produkte verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer" rel="follow noopener">https://policies.google.com/privacy?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="linkedin-insight-tag-datenschutzerklaerung" >LinkedIn Insight-Tag Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir nutzen auf unserer Website das Conversion-Tracking-Tool LinkedIn Insight-Tag. Dienstanbieter ist das amerikanische Unternehmen LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Für die datenschutzrelevanten Aspekte im Europäischen Wirtschaftsraum (EWR), der EU und der Schweiz ist das Unternehmen LinkedIn Ireland Unlimited (Wilton Place, Dublin 2, Irland) verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">LinkedIn verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet LinkedIn sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich LinkedIn, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr Informationen zu den Standardvertragsklauseln bei LinkedIn finden Sie unter <a  href="https://de.linkedin.com/legal/l/dpa" target="_blank" rel="noreferrer" rel="follow noopener">https://de.linkedin.com/legal/l/dpa</a> oder <a  href="https://www.linkedin.com/legal/l/eu-sccs" target="_blank" rel="noreferrer" rel="follow noopener">https://www.linkedin.com/legal/l/eu-sccs</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Näheres über LinkedIn Insight-Tag erfahren Sie unter <a  href="https://www.linkedin.com/help/linkedin/answer/a427660" target="_blank" rel="noreferrer" rel="follow noopener">https://www.linkedin.com/help/linkedin/answer/a427660</a>. Mehr über die Daten, die durch die Verwendung von LinkedIn Insight-Tag verarbeitet werden, erfahren Sie auch in der Datenschutzerklärung auf <a  href="https://de.linkedin.com/legal/privacy-policy">https://de.linkedin.com/legal/privacy-policy</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="cookie-consent-management-platform-einleitung" >Cookie Consent Management Platform Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Cookie Consent Management Platform Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Website Besucher<br />
&#x1f91d; Zweck: Einholung und Verwaltung der Zustimmung zu bestimmten Cookies und somit dem Einsatz bestimmter Tools<br />
&#x1f4d3; Verarbeitete Daten: Daten zur Verwaltung der eingestellten Cookie-Einstellungen wie IP-Adresse, Zeitpunkt der Zustimmung, Art der Zustimmung, einzelne Zustimmungen. Mehr Details dazu finden Sie beim jeweils eingesetzten Tool.<br />
&#x1f4c5; Speicherdauer: Hängt vom eingesetzten Tool ab, man muss sich auf Zeiträume von mehreren Jahren einstellen<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist eine Cookie Consent Manangement Platform?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website eine Consent Management Platform (CMP) Software, die uns und Ihnen den korrekten und sicheren Umgang mit verwendeten Skripten und Cookies erleichtert. Die Software erstellt automatisch ein Cookie-Popup, scannt und kontrolliert alle Skripts und Cookies, bietet eine datenschutzrechtlich notwendige Cookie-Einwilligung für Sie und hilft uns und Ihnen den Überblick über alle Cookies zu behalten. Bei den meisten Cookie Consent Management Tools werden alle vorhandenen Cookies identifiziert und kategorisiert. Sie als Websitebesucher entscheiden dann selbst, ob und welche Skripte und Cookies Sie zulassen oder nicht zulassen. Die folgende Grafik stellt die Beziehung zwischen Browser, Webserver und CMP dar.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<img role="img" src="https://www.adsimple.at/wp-content/uploads/2021/03/consent-management-platform-overview.svg" alt="Consent Management Platform Überblick" width="100%" />
</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir ein Cookie-Management-Tool?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Unser Ziel ist es, Ihnen im Bereich Datenschutz die bestmögliche Transparenz zu bieten. Zudem sind wir dazu auch rechtlich verpflichtet. Wir wollen Sie über alle Tools und alle Cookies, die Daten von Ihnen speichern und verarbeiten können, so gut wie möglich aufklären. Es ist auch Ihr Recht, selbst zu entscheiden, welche Cookies Sie akzeptieren und welche nicht. Um Ihnen dieses Recht einzuräumen, müssen wir zuerst genau wissen, welche Cookies überhaupt auf unserer Website gelandet sind. Dank eines Cookie-Management-Tools, welches die Website regelmäßig nach allen vorhandenen Cookies scannt, wissen wir über alle Cookies Bescheid und können Ihnen DSGVO-konform Auskunft darüber geben. Über das Einwilligungssystem können Sie dann Cookies akzeptieren oder ablehnen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Im Rahmen unseres Cookie-Management-Tools können Sie jedes einzelnen Cookies selbst verwalten und haben die vollständige Kontrolle über die Speicherung und Verarbeitung Ihrer Daten. Die Erklärung Ihrer Einwilligung wird gespeichert, damit wir Sie nicht bei jedem neuen Besuch unserer Website abfragen müssen und wir Ihre Einwilligung, wenn gesetzlich nötig, auch nachweisen können. Gespeichert wird dies entweder in einem Opt-in-Cookie oder auf einem Server. Je nach Anbieter des Cookie-Management-Tools variiert Speicherdauer Ihrer Cookie-Einwilligung. Meist werden diese Daten (etwa pseudonyme User-ID, Einwilligungs-Zeitpunkt, Detailangaben zu den Cookie-Kategorien oder Tools, Browser, Gerätinformationen) bis zu zwei Jahren gespeichert.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Dauer der Datenverarbeitung</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert. Manche Cookies werden bereits nach dem Verlassen der Website wieder gelöscht, andere können über einige Jahre in Ihrem Browser gespeichert sein. Die genaue Dauer der Datenverarbeitung hängt vom verwendeten Tool ab, meistens sollten Sie sich auf eine Speicherdauer von mehreren Jahren einstellen. In den jeweiligen Datenschutzerklärungen der einzelnen Anbieter erhalten Sie in der Regel genaue Informationen über die Dauer der Datenverarbeitung.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen Cookie-Management-Tools, erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie Cookies zustimmen, werden über diese Cookies personenbezogene Daten von Ihnen verarbeitet und gespeichert. Falls wir durch Ihre <strong >Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) Cookies verwenden dürfen, ist diese Einwilligung auch gleichzeitig die Rechtsgrundlage für die Verwendung von Cookies bzw. die Verarbeitung Ihrer Daten. Um die Einwilligung zu Cookies verwalten zu können und Ihnen die Einwilligung ermöglichen zu können, kommt eine Cookie-Consent-Management-Platform-Software zum Einsatz. Der Einsatz dieser Software ermöglicht uns, die Website auf effiziente Weise rechtskonform zu betreiben, was ein <strong >berechtigtes Interesse</strong> (Artikel 6 Abs. 1 lit. f DSGVO) darstellt.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="cookiebot-datenschutzerklaerung" >Cookiebot Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Cookiebot Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Website Besucher<br />
&#x1f91d; Zweck: Einholung der Zustimmung zu bestimmten Cookies und somit dem Einsatz bestimmter Tools<br />
&#x1f4d3; Verarbeitete Daten:  Daten zur Verwaltung der eingestellten Cookie-Einstellungen wie IP-Adresse, Zeitpunkt der Zustimmung, Art der Zustimmung, einzelne Zustimmungen. Mehr Details dazu finden Sie beim jeweils eingesetzten Tool.<br />
&#x1f4c5; Speicherdauer: die Daten werden nach einem Jahr gelöscht<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist Cookiebot?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden auf unserer Website Funktionen des Anbieters Cookiebot. Hinter Cookiebot steht das Unternehmen Cybot A/S, Havnegade 39, 1058 Kopenhagen, DK. Cookiebot bietet uns unter anderem die Möglichkeit, Ihnen einen umfangreichen Cookie-Hinweis (auch Cookie-Banner oder Cookie-Notice genannt) zu liefern. Durch die Verwendung dieser Funktion können Daten von Ihnen an Cookiebot bzw. Cybot gesendet, gespeichert und verarbeitet werden. In dieser Datenschutzerklärung informieren wir Sie warum wir Cookiebot nutzen, welche Daten übertragen werden und wie Sie diese Datenübertragung verhindern können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Cookiebot ist ein Software-Produkt des Unternehmens Cybot. Die Software erstellt automatisch einen DSGVO-konformen Cookie-Hinweis für unserer Websitebesucher. Zudem scannt, kontrolliert und wertet die Technologie hinter Cookiebot alle Cookies und Tracking-Maßnahmen auf unserer Website.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Cookiebot auf unserer Website?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Datenschutz nehmen wir sehr ernst. Wir wollen Ihnen ganz genau zeigen, was auf unserer Website vor sich geht und welche Ihrer Daten gespeichert werden. Cookiebot hilft uns einen guten Überblick über all unsere Cookies (Erst- und Drittanbieter-Cookies) zu erhalten. So können wir Sie über die Nutzung von Cookies auf unserer Website exakt und transparent informieren. Sie bekommen stets einen aktuellen und datenschutzkonformen Cookie-Hinweis und entscheiden selbst, welche Cookies Sie zulassen und welche nicht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden von Cookiebot gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie Cookies zulassen, werden folgende Daten an Cybot übertragen, gespeichert und verarbeitet.</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">IP-Adresse (in anonymisierter Form, die letzten 3 Ziffern werden auf 0 gesetzt)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Datum und Uhrzeit Ihres Einverständnisses</li>
<li className="mt-6 text-lg leading-8 text-gray-600">unsere Website-URL</li>
<li className="mt-6 text-lg leading-8 text-gray-600">technische Browserdaten</li>
<li className="mt-6 text-lg leading-8 text-gray-600">verschlüsselter, anonymer Key</li>
<li className="mt-6 text-lg leading-8 text-gray-600">die Cookies, die Sie zugelassen haben (als Zustimmungsnachweis)</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">Folgenden Cookies werden von Cookiebot gesetzt, wenn Sie der Verwendung von Cookies zugestimmt haben:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> CookieConsent<br />
<strong >Wert:</strong> &#123;stamp:&#8217;P7to4eNgIHvJvDerjKneBsmJQd9312492666-2<br />
<strong >Verwendungszweck:</strong> In diesem Cookie wird Ihr Zustimmungsstatus, gespeichert. Dadurch kann unsere Website auch bei zukünftigen Besuchen den aktuellen Status lesen und befolgen.<br />
<strong >Ablaufdatum:</strong> nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> CookieConsentBulkTicket<br />
<strong >Wert:</strong> kDSPWpA%2fjhljZKClPqsncfR8SveTnNWhys5NojaxdFYBPjZ2PaDnUw%3d%3312492666-6<br />
<strong >Verwendungszweck:</strong> Dieses Cookie wird gesetzt, wenn Sie alle Cookies erlauben und somit eine &#8220;Sammelzustimmung&#8221; aktiviert haben. Das Cookie speichert dann eine eigene, zufällige und eindeutige ID.<br />
<strong >Ablaufdatum: </strong>nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Anmerkung: </strong>Bitte bedenken Sie, dass es sich hier um eine beispielhafte Liste handelt und wir keinen Anspruch auf Vollständigkeit erheben können. In der Cookie-Erklärung unter <a  href="https://www.cookiebot.com/de/cookie-declaration/" target="_blank" rel="noreferrer" rel="follow noopener noreferrer">https://www.cookiebot.com/de/cookie-declaration/</a> sehen Sie, welche weiteren Cookies eingesetzt werden können.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Laut der Datenschutzerklärung von Cybot verkauft das Unternehmen personenbezogene Daten nicht weiter. Cybot gibt allerdings Daten an vertrauensvolle Dritt- oder Subunternehmen weiter, die dem Unternehmen helfen, die eigenen betriebswirtschaftlichen Ziele zu erreichen. Daten werden auch dann weitergegeben, wenn dies rechtlich erforderlich ist.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange und wo werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Alle erhobenen Daten werden ausschließlich innerhalb der Europäischen Union übertragen, gespeichert und weitergeleitet. Die Daten werden in einem Azure-Rechenzentrum (Cloud-Anbieter ist Microsoft) gespeichert. Auf <a  href="https://azure.microsoft.com/de-de/explore/global-infrastructure/geographies/" target="_blank" rel="noreferrer" rel="follow noopener">https://azure.microsoft.com/de-de/explore/global-infrastructure/geographies/</a> erfahren Sie mehr über alle „Azure-Regionen“. Alle User Daten werden von Cookiebot nach 12 Monaten ab der Registrierung (Cookie-Zustimmung) bzw. unmittelbar nach Kündigung des Cookiebot-Services gelöscht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu löschen. Die Datenerfassung und Speicherung können Sie beispielsweise verhindern, indem Sie über den Cookie-Hinweis die Verwendung von Cookies ablehnen. Eine weitere Möglichkeit die Datenverarbeitung zu unterbinden bzw. nach Ihren Wünschen zu verwalten, bietet Ihr Browser. Je nach Browser funktioniert die Cookie-Verwaltung etwas anders. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie Cookies zustimmen, werden über diese Cookies personenbezogene Daten von Ihnen verarbeitet und gespeichert. Falls wir durch Ihre <strong >Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) Cookies verwenden dürfen, ist diese Einwilligung auch gleichzeitig die Rechtsgrundlage für die Verwendung von Cookies bzw. die Verarbeitung Ihrer Daten. Um die Einwilligung zu Cookies verwalten zu können und Ihnen die Einwilligung ermöglichen zu können, kommt der Cookiebot zum Einsatz. Der Einsatz dieser Software ermöglicht uns, die Website auf effiziente Weise rechtskonform zu betreiben, was ein <strong >berechtigtes Interesse</strong> (Artikel 6 Abs. 1 lit. f DSGVO) darstellt.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie mehr über die Datenschutzrichtlinien von „Cookiebot“ bzw. dem dahinterstehenden Unternehmen Cybot erfahren wollen, empfehlen wir Ihnen die Datenschutzrichtlinien unter <a  href="https://www.cookiebot.com/de/privacy-policy/?tid=312492666" target="_blank" rel="noreferrer" rel="noopener noreferrer">https://www.cookiebot.com/de/privacy-policy/</a> durchzulesen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">&nbsp;</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="security-anti-spam" >Security &amp; Anti-Spam</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Security &amp; Anti-Spam Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Cybersicherheit<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse, Name oder technische Daten wie etwa Browserversion<br />
Mehr Details dazu finden Sie weiter unten und den einzelnen Datenschutztexten.<br />
&#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der Dienstleistung nicht mehr benötigt werden<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist eine Security- &amp; Anti-Spam-Software?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Mit sogenannten Security- &amp; Anti-Spam-Softwares können Sie sich und wir uns vor diversen Spam- oder Phishing-Mails und möglichen anderen Cyberattacken schützen. Unter Spam versteht man Werbemails aus einem Massenversand, die man selbst nicht verlangte. Solche Mails werden auch Datenmüll genannt und können auch Kosten verursachen. Phishing-Mails sind wiederum Nachrichten, die darauf abzielen, über gefälschte Nachrichten oder Websites Vertrauen aufzubauen, um an persönliche Daten zu gelangen. Eine Anti-Spam-Software schützt in der Regel vor unerwünschten Spam-Nachrichten oder bösartigen Mails, die etwa Viren in unser System einschleusen könnten. Wir nutzen auch allgemeine Firewall- und Sicherheitssysteme, die unsere Computer vor unerwünschten Netzwerkangriffen schützen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Security- &amp; Anti-Spam-Software?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir legen auf unserer Website besonders großen Wert auf Sicherheit. Schließlich geht es nicht nur um unsere, sondern vor allem auch um Ihre Sicherheit. Leider gehören mittlerweile in der Welt der IT und des Internets Cyberbedrohungen schon zum Alltag. Oft versuchen Hacker mit Hilfe einer Cyberattacke personenbezogene Daten aus einem IT-System zu stehlen. Und daher ist ein gutes Abwehrsystem absolut notwendig. Ein Sicherheitssystem überwacht alle ein- und ausgehenden Verbindungen zu unserem Netzwerk bzw. Computer. Damit wir noch größere Sicherheit vor Cyberangriffen erreichen, nutzen wir neben den standardisierten Sicherheitssystemen auf unserem Computer auch noch weitere externe Security-Dienste. Unerlaubter Verkehr von Daten wird dadurch besser unterbunden und so schützen wir uns vor Cyberkriminalität.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden durch Security- &amp; Anti-Spam-Softwares verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Welche Daten genau erhoben und gespeichert werden hängt natürlich vom jeweiligen Dienst ab. Wir sind allerdings stets bemüht nur Programme zu verwenden, die sehr sparsam Daten erheben bzw. nur Daten speichern, die für die Erfüllung der angebotenen Leistung nötig sind. Grundsätzlich kann der Dienst Daten wie beispielsweise Name, Adresse, IP-Adresse, E-Mail-Adresse und technische Daten wie Browsertyp oder Browserversion speichern. Auch können etwaige Leistungs- und Protokolldaten erhoben werden, um mögliche eingehende Bedrohungen rechtzeitig zu erkennen. Diese Daten werden im Rahmen der Services und unter Einhaltung der geltenden Gesetze verarbeitet. Dazu zählt auch bei US-amerikanischen Anbietern (über die Standardvertragsklauseln) die DSGVO. Diese Security-Dienste arbeiten in einigen Fällen auch mit Drittanbietern zusammen, die unter Anweisung und in Übereinstimmung mit den Datenschutzrichtlinien und weiteren Sicherheitsmaßnahmen Daten speichern und/oder verarbeiten können. Die Datenspeicherung erfolgt meist über Cookies.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Dauer der Datenverarbeitung</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichern Security-Programme Daten bis Sie oder wir die Datenspeicherung widerrufen. Generell werden personenbezogene Daten nur so lange, wie es für die Bereitstellung der Dienstleistungen unbedingt notwendig ist, gespeichert. In vielen Fällen fehlen uns leider von den Anbietern präzise Informationen über die Länge der Speicherung.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern von Security-Software zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Da bei solchen Sicherheitsdiensten auch Cookies zum Einsatz kommen können, empfehlen wir Ihnen unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir setzen die Sicherheitsdienste hauptsächlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) an einem guten Sicherheitssystem gegen diverse Cyberangriffe ein.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Sicherheitsfunktionen bedürfen Ihrer Einwilligung. Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Security-Dienste verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO). Die meisten von uns verwendeten Dienste setzen Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen Tools erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-recaptcha-datenschutzerklaerung" >Google reCAPTCHA Datenschutzerklärung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Google reCAPTCHA Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Optimierung unserer Serviceleistung und Schutz vor Cyberangriffen<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse, Browserinformationen, Ihr Betriebssystem, eingeschränkte Standorts- und Nutzungsdaten<br />
Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
&#x1f4c5; Speicherdauer: abhängig von den gespeicherten Daten<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was ist reCAPTCHA?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Unser oberstes Ziel ist es, unsere Webseite für Sie und für uns bestmöglich zu sichern und zu schützen. Um das zu gewährleisten, verwenden wir Google reCAPTCHA der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit reCAPTCHA können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch und Blut sind und kein Roboter oder eine andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg, unerwünschte Information, die uns ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie einfach ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie das genau funktioniert und vor allem welche Daten dafür verwendet werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den Missbrauch durch nicht-menschliche Besucher schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie Formulare im Internet ausfüllen. Ein Captcha-Dienst ist eine Art automatischer Turing-Test, der sicherstellen soll, dass eine Handlung im Internet von einem Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest. Bei Captchas übernimmt das auch der Computer bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben, die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext eingebunden und dann läuft das Tool im Hintergrund und analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet die Software einen sogenannten Captcha-Score. Google berechnet mit diesem Score schon vor der Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Sie ein Mensch sind. reCAPTCHA bzw. Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.) manipulieren oder missbrauchen könnten.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir reCAPTCHA auf unserer Website?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma Google. So können wir uns ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden Daten an Google übermittelt, um festzustellen, ob Sie auch wirklich ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA können wir solche Botangriffe vermeiden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden von reCAPTCHA gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">reCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen auf unserer Webseite auch wirklich von Menschen stammen. Es kann also die IP-Adresse und andere Daten, die Google für den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen werden innerhalb der Mitgliedstaaten der EU oder anderer Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit Ihrem Google-Konto angemeldet sind. Zuerst prüft der reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.) platziert sind. Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser und erfasst einen Schnappschuss Ihres Browserfensters.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.</p>
<ul className="list-disc">
<li className="mt-6 text-lg leading-8 text-gray-600">Referrer URL (die Adresse der Seite von der der Besucher kommt)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">IP-Adresse (z.B. 256.123.123.1)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur ausführen wird gespeichert)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC voreingestellt haben wird gespeichert)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten unter einem Namen sammeln)</li>
<li className="mt-6 text-lg leading-8 text-gray-600">Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)</li>
</ul>
<p className="mt-6 text-lg leading-8 text-gray-600">Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche Daten Google genau speichert, erfährt man von Google nicht im Detail.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir uns auf die reCAPTCHA Demo-Version von Google unter <a  href="https://www.google.com/recaptcha/api2/demo" target="_blank" rel="noreferrer" rel="noopener noreferrer">https://www.google.com/recaptcha/api2/demo</a>. All diese Cookies benötigen zu Trackingzwecken eine eindeutige Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der Demo-Version gesetzt hat:</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> IDE<br />
<strong >Wert:</strong> WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-312492666-8<br />
<strong >Verwendungszweck:</strong> Dieses Cookie wird von der Firma DoubleClick (gehört auch Google) gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit Werbeanzeigen zu registrieren und zu melden. So kann die Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen getroffen werden. IDE wird in Browsern unter der Domain doubleclick.net gespeichert.<br />
<strong >Ablaufdatum:</strong> nach einem Jahr</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> 1P_JAR<br />
<strong >Wert:</strong> 2019-5-14-12<br />
<strong >Verwendungszweck:</strong> Dieses Cookie sammelt Statistiken zur Webseite-Nutzung und misst Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem Käufer wird. Das Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie vermeiden, dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.<br />
<strong >Ablaufdatum:</strong> nach einem Monat</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> ANID<br />
<strong >Wert:</strong> U7j1v3dZa3124926660xgZFmiqWppRWKOr<br />
<strong >Verwendungszweck:</strong> Viele Infos konnten wir über dieses Cookie nicht in Erfahrung bringen. In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang mit „Werbecookies“ wie z. B. &#8220;DSID&#8221;, &#8220;FLC&#8221;, &#8220;AID&#8221;, &#8220;TAID&#8221; erwähnt. ANID wird unter Domain google.com gespeichert.<br />
<strong >Ablaufdatum:</strong> nach 9 Monaten</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> CONSENT<br />
<strong >Wert: </strong>YES+AT.de+20150628-20-0<br />
<strong >Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen, Betrügereien von Anmeldeinformationen zu verhindern und Userdaten vor unbefugten Angriffen zu schützen.<br />
<strong >Ablaufdatum:</strong> nach 19 Jahren</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> NID<br />
<strong >Wert:</strong> 0WmuWqy312492666zILzqV_nmt3sDXwPeM5Q<br />
<strong >Verwendungszweck:</strong> NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, um persönliche Einstellungen des Users für Werbezwecke zu sammeln.<br />
<strong >Ablaufdatum:</strong> nach 6 Monaten</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Name:</strong> DV<br />
<strong >Wert:</strong> gEAABBCjJMXcI0dSAAAANbqc312492666-4<br />
<strong >Verwendungszweck:</strong> Sobald Sie das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird dieses Cookie gesetzt. Das Cookie wird von Google Analytics für personalisierte Werbung verwendet. DV sammelt Informationen in anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen zu treffen.<br />
<strong >Ablaufdatum:</strong> nach 10 Minuten</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
<strong >Anmerkung: </strong>Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange und wo werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den Google-Server übertragen. Wo genau diese Daten gespeichert werden, stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine Bestätigung von Google erhalten zu haben, ist davon auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der Webseite oder Spracheinstellungen auf den europäischen oder amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus weiteren Google-Diensten zusammengeführt. Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt.<strong > </strong>Dafür gelten die abweichenden Datenschutzbestimmungen der Firma Google.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die Daten sobald Sie unsere Seite aufrufen automatisch an Google übermittelt. Um diese Daten wieder zu löschen, müssen Sie den Google-Support auf  <a  href="https://support.google.com/?hl=de&amp;tid=312492666" target="_blank" rel="noreferrer" rel="noopener noreferrer">https://support.google.com/?hl=de&amp;tid=312492666</a> kontaktieren.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden, dass Google LLC und deren Vertreter automatisch Daten erheben, bearbeiten und nutzen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eingewilligt haben, dass Google reCAPTCHA eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong > Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google reCAPTCHA vorkommen kann, dar.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Von unserer Seite besteht zudem ein berechtigtes Interesse, Google reCAPTCHA zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong >Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google reCAPTCHA gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von Google auf <a  href="https://developers.google.com/recaptcha/" target="_blank" rel="noreferrer" rel="noopener noreferrer">https://developers.google.com/recaptcha/</a>. Google geht hier zwar auf die technische Entwicklung der reCAPTCHA näher ein, doch genaue Informationen über Datenspeicherung und datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute Übersicht über die grundsätzliche Verwendung von Daten bei Google finden Sie in der hauseigenen Datenschutzerklärung auf <a  href="https://policies.google.com/privacy?hl=de&amp;tid=312492666" target="_blank" rel="noreferrer" rel="noopener noreferrer">https://www.google.com/intl/de/policies/privacy/</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="cloud-dienste" >Cloud-Dienste</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Cloud-Dienste Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Wir als Websitebetreiber und Sie als Websitebesucher<br />
&#x1f91d; Zweck: Sicherheit und Datenspeicherung<br />
&#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse, Name oder technische Daten wie etwa Browserversion<br />
Mehr Details dazu finden Sie weiter unten und den einzelnen Datenschutztexten bzw. in den Datenschutzerklärungen der Anbieter<br />
&#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der Dienstleistung nicht mehr benötigt werden<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was sind Cloud-Dienste?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Cloud-Dienste stellen uns als Websitebetreiber Speicherplatz und Rechenleistung über das Internet zur Verfügung. Über das Internet können Daten an ein externes System übertragen, verarbeitet und gespeichert werden. Die Verwaltung dieser Daten übernimmt der entsprechende Cloud-Anbieter. Je nach Anforderung kann eine einzelne Person oder auch ein Unternehmen die Speicherplatzgröße oder Rechenleistung wählen. Zugegriffen wird auf Cloud-Speicher über eine API oder über Speicherprotokolle. API steht für Application Programming Interface und gemeint ist damit eine Programmierschnittstelle, die Software- mit Hardwarekomponenten verbindet.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Cloud-Dienste?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir nutzen Cloud-Dienste aus mehreren Gründen. Ein Cloud-Dienst bietet uns die Möglichkeit unsere Daten sicher zu speichern. Zudem haben wir von verschiedenen Orten und Geräten Zugriff auf die Daten und verfügen damit über mehr Flexibilität und erleichtern unsere Arbeitsprozesse. Ein Cloud-Speicher erspart uns auch Kosten, weil wir keine eigene Infrastruktur für Datenspeicherung und Datensicherheit errichten und verwalten müssen. Durch die zentrale Speicherung unserer Daten in der Cloud können wir auch unsere Anwendungsfelder erweitern und unsere Informationen deutlich besser verwalten.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir als Websitebetreiber bzw. als Unternehmen setzen Cloud-Dienste also in erster Linie für unsere eigenen Zwecke ein. Zum Beispiel nutzen wir die Dienste, um unseren Kalender zu verwalten, um Dokumente oder andere wichtige Informationen in der Cloud zu speichern. Dabei können allerdings auch personenbezogene Daten von Ihnen gespeichert werden. Dies ist beispielsweise dann der Fall, wenn Sie uns Ihre Kontaktdaten (etwa Name und E-Mail-Adresse) zu Verfügung stellen und wir unsere Kundendaten bei einem Cloud-Anbieter speichern. Folglich können Daten, die wir von Ihnen verarbeiten auch auf externen Servern abgelegt und verarbeitet werden. Wenn wir auf unserer Website bestimmte Formulare oder Inhalte von Cloud-Diensten anbieten, können auch Cookies für Webanalysen und Werbezwecke gesetzt werden. Weiters merken sich solche Cookies Ihre Einstellungen (wie z. B. die verwendete Sprache), damit Sie beim nächsten Besuch auf unserer Website Ihre gewohnte Webumgebung vorfinden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden durch Cloud-Dienste verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Viele von uns in der Cloud gespeicherten Daten haben keinen Personenbezug, einige Daten zählen jedoch, nach Definition der DSGVO, zu personenbezogenen Daten. Häufig handelt es sich um Kundendaten wie Name, Adresse, IP-Adresse oder Telefonnummer oder um technische Gerätinformationen. In der Cloud können des Weiteren auch Videos, Bilder und Audiodateien gespeichert werden. Wie die Daten genau erhoben und gespeichert werden, hängt vom jeweiligen Dienst ab. Wir versuchen nur Dienste zu nutzen, die sehr vertrauenswürdig und professionell mit den Daten umgehen. Grundsätzlich haben die Dienste, wie etwa Amazon Drive, Zugriff auf die gespeicherten Dateien, um ihren eigenen Service entsprechend anbieten zu können. Dafür benötigen die Dienste allerdings Genehmigungen wie beispielsweise das Recht Dateien wegen Sicherheitsaspekten zu kopieren. Diese Daten werden im Rahmen der Services und unter Einhaltung der geltenden Gesetze verarbeitet und verwaltet. Dazu zählt auch bei US-amerikanischen Anbietern (über die Standardvertragsklauseln) die DSGVO. Diese Cloud-Dienste arbeiten in einigen Fällen auch mit Drittanbietern zusammen, die unter Anweisung und in Übereinstimmung mit den Datenschutzrichtlinien und weiteren Sicherheitsmaßnahmen Daten verarbeiten können. Wir möchten an dieser Stelle nochmals betonen, dass sich alle bekannten Cloud-Dienste (wie Amazon Drive, Google Drive oder Microsoft Onedrive) das Recht einholen, Zugriff auf gespeicherte Inhalte zu haben, um ihr eigenes Service entsprechend anbieten und optimieren zu können.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Dauer der Datenverarbeitung</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Im Allgemeinen speichern Cloud-Dienste Daten, bis Sie oder wir die Datenspeicherung widerrufen bzw. die Daten wieder löschen. Generell werden personenbezogene Daten nur so lange gespeichert, wie es für die Bereitstellung der Dienstleistungen unbedingt notwendig ist. Ein endgültiges Datenlöschen aus der Cloud kann allerdings einige Monate dauern. Das ist der Fall, weil die Daten meist nicht nur auf einem Server gespeichert sind, sondern auf verschiedenen Servern aufgeteilt werden.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Datenspeicherung in einer Cloud zu widerrufen. Falls Cookies verwendet werden, haben Sie auch hier ein Widerrufsrecht. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Wir empfehlen Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Cloud-Anbieter durchlesen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir setzen Cloud-Dienste hauptsächlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) an einem guten Sicherheits- und Speichersystem ein.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Speicherfunktionen bedürfen Ihrer Einwilligung. Wenn Sie eingewilligt haben, dass Daten von Ihnen bei Cloud-Diensten verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO). Die meisten von uns verwendeten Dienste setzen Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu speziellen Tools erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-cloud-datenschutzerklaerung" >Google Cloud Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden für unserer Website Google Cloud, einen Online-Speicherdienst für Dateien, Fotos und Videos. Dienstanbieter ist das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google hält einen Vertrag über die Auftragsverarbeitung gemäß Art. 28 DSGVO bereit, der als datenschutzrechtliche Grundlage für unsere Kundenbeziehung zu Google fungiert. Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln. Hier finden Sie die Auftragsverarbeitungsbedingungen: <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Google Cloud verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="bewertungsplattformen-einleitung" >Bewertungsplattformen Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Bewertungsplattformen Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website oder einer Bewertungsplattform<br />
&#x1f91d; Zweck: Feedback zu unseren Produkten und/oder Dienstleistungen<br />
&#x1f4d3; Verarbeitete Daten: U.a. IP-Adresse, E-Mail-Adresse, Name. Mehr Details dazu finden Sie weiter unten bzw. bei den jeweils eingesetzten Bewertungsplattformen.<br />
&#x1f4c5; Speicherdauer: abhängig von der jeweiligen Plattform<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen),</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was sind Bewertungsplattformen?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Auf verschiedenen Bewertungsplattformen können Sie unsere Produkte oder Dienstleistungen bewerten. Wir sind Teilnehmer mancher dieser Plattformen, damit wir Feedback von Ihnen bekommen und so unser Angebot optimieren können. Wenn Sie uns über eine Bewertungsplattform bewerten, gelten die Datenschutzerklärung und die allgemeinen Geschäftsbedingungen des jeweiligen Bewertungs-Service. Sehr häufig müssen Sie sich auch registrieren, um eine Bewertung abzugeben. Es können auch Bewertungstechnologien (Widgets) in unsere Website eingebunden werden. Durch die Verwendung eines solchen eingebundenen Tools werden auch Daten an den entsprechenden Anbieter übertragen, verarbeitet und gespeichert.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Viele dieser eingebundenen Programme funktionieren nach ähnlichem Prinzip. Nachdem Sie bei uns ein Produkt bestellt haben oder eine Dienstleistung in Anspruch genommen haben, werden Sie, per E-Mail oder auf der Website, gebeten eine Bewertung abzugeben. Sie werden dafür meist über einen Link auf eine Bewertungsseite weitergeleitet und können dort einfach und schnell eine Bewertung erstellen. Manche Bewertungssysteme bieten auch eine Schnittstelle zu diversen Social-Media-Kanälen, um das Feedback mehreren Menschen zugänglich zu machen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir Bewertungsplattformen?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Bewertungsplattformen sammeln Feedback und Bewertungen über unsere Angebote. Durch Ihre Bewertungen bekommen wir schnell eine entsprechende Rückmeldung und können unsere Produkte und/oder Dienstleistungen viel effizienter verbessern. Die Bewertungen dienen uns folglich einerseits der Optimierung unserer Angebote und andererseits geben sie Ihnen und all unseren zukünftigen Kunden einen guten Überblick über die Qualität unserer Produkte und Leistungen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Mithilfe Ihrer Einwilligung übermitteln wir Informationen über Sie und die von Ihnen in Anspruch bezogenen Leistungen an die entsprechende Bewertungsplattform. Dies machen wir, um sicherzustellen, dass Sie auch wirklich eine unserer Leistungen in Anspruch genommen haben. Denn nur dann können Sie auch echtes Feedback geben. Die übermittelten Daten dienen lediglich der User-Erkennung. Welche Daten genau gespeichert und verarbeitet werden, hängt natürlich von den verwendeten Anbietern ab. Meistens werden den Bewertungsplattformen auch personenbezogene Daten wie IP-Adresse, E-Mail-Adresse oder Ihr Name zur Verfügung gestellt. Es werden auch nach Abgabe Ihre Bewertung Bestellinformationen wie etwa die Bestellnummer eines erworbenen Artikels an die entsprechende Plattform weitergeleitet. Wenn Ihre E-Mail-Adresse übermittelt wird, geschieht dies, damit die Bewertungsplattform Ihnen eine Mail nach dem Erwerb eines Produktes senden kann. Damit wir auch Ihre Bewertung in unsere Website einbinden können, geben wir den Anbietern auch die Information, dass Sie unsere Seite aufgerufen haben. Verantwortlich für die erhobenen personenbezogenen Daten ist die verwendete Bewertungsplattform.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Wie lange und wo werden die Daten gespeichert?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Genaueres über die Dauer der Datenverarbeitung erfahren Sie weiter unten in der entsprechenden Datenschutzerklärung des Anbieters, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Personenbezogene Daten, die in einer Bewertung genannt werden, werden in der Regel von Mitarbeitern der verwendeten Plattform anonymisiert und sind somit nur für Administratoren des Unternehmens sichtbar. Die erhobenen Daten werden auf den Servern der Anbieter gespeichert und bei den meisten Anbietern nach Auftragsende gelöscht.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Widerspruchsrecht</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Beispiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn Sie eingewilligt haben, dass eine Bewertungsplattform eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Bewertungsportal vorkommen kann, dar.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Von unserer Seite besteht zudem ein berechtigtes Interesse, eine Bewertungsplattform zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen eine Bewertungsplattform gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir hoffen, wir konnten Ihnen die wichtigsten allgemeinen Informationen rund um die Datenverarbeitung von Bewertungsplattformen näherbringen. Nähere Informationen finden Sie weiter unten in den Datenschutztexten bzw. in den verlinkten Datenschutzerklärungen des Unternehmens.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="google-kundenrezensionen-datenschutzerklaerung" >Google Kundenrezensionen Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir nutzen für unsere Website auch die Bewertungsplattform Google Kundenrezensionen. Dienstanbieter ist das amerikanische Unternehmen Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.<span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;belboon GmbH, Weinmeisterstr. 12–14, 10178 Berlin, Deutschland.&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0}"> </span>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Datenverarbeitungsbedingungen für Google Werbeprodukte (Google Ads Controller-Controller Data Protection Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a  href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="noreferrer" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Google verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf <a  href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noreferrer" rel="follow noopener">https://policies.google.com/privacy?hl=de</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="provenexpert-datenschutzerklaerung" >ProvenExpert Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir nutzen für unsere Website auch die Bewertungsplattform ProvenExpert. Dienstanbieter ist das deutsche Unternehmen <span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Expert Systems AG, Quedlinburger Straße 1, 10589 Berlin, Deutschland.&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0}">Expert Systems AG, Quedlinburger Straße 1, 10589 Berlin, Deutschland. </span>
<span  data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;belboon GmbH, Weinmeisterstr. 12–14, 10178 Berlin, Deutschland.&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:3,&quot;12&quot;:0}">Mehr über die Daten, die durch die Verwendung von ProvenExpert verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf <a  href="https://www.provenexpert.com/de-de/datenschutzbestimmungen/" target="_blank" rel="noreferrer" rel="follow noopener">https://www.provenexpert.com/de-de/datenschutzbestimmungen/</a>. </span>
</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="sonstiges-einleitung" >Sonstiges Einleitung</h2>
<table border="1" cellpadding="15">
<tbody>
<tr>
<td>
<strong >Sonstiges Datenschutzerklärung Zusammenfassung</strong>
<br />
&#x1f465; Betroffene: Besucher der Website<br />
&#x1f91d; Zweck: Verbesserung der Nutzererfahrung<br />
&#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten Diensten ab. Meist handelt es sich um IP-Adresse und/oder technische Daten. Mehr Details dazu finden Sie bei den jeweils eingesetzten Tools.<br />
&#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools<br />
&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
</tr>
</tbody>
</table>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Was fällt unter „Sonstiges“?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Unter die Kategorie „Sonstiges“ fallen jene Dienste, die nicht in eine der oben genannten Kategorien passen. Dabei handelt es sich in der Regel um diverse Plugins und eingebundene Elemente, die unsere Website verbessern. In der Regel werden diese Funktionen von Drittanbietern bezogen und in unsere Website eingebunden. Beispielsweise handelt es sich dabei um Websuch-Dienste wie Algolia Place, Giphy, Programmable Search Engine oder Onlinedienste für Wetterdaten wie etwa OpenWeather.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Warum verwenden wir weitere Drittanbieter?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir wollen Ihnen mit unserer Website das beste Webangebot in unserer Branche bieten. Schon lange ist eine Website nicht bloß eine reine Visitenkarte für Unternehmen. Vielmehr ist es ein Ort, der Ihnen behilflich sein soll, zu finden wonach Sie suchen. Um stets unsere Website für Sie noch interessanter und hilfreicher zu machen, nutzen wir diverse Dienste von Drittanbietern.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Welche Daten werden verarbeitet?</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Immer wenn Elemente in unsere Website eingebunden werden, wird Ihre IP-Adresse an den jeweiligen Anbieter übermittelt, gespeichert und dort verarbeitet werden. Das ist nötig, weil sonst die Inhalte nicht an Ihren Browser gesendet werden und folglich nicht entsprechend dargestellt werden. Es kann auch vorkommen, dass Dienstanbieter auch Pixel-Tags bzw. Web-Beacons verwenden. Das sind kleine Grafiken auf Websites, die eine Logdatei aufzeichnen und auch Analysen dieser Datei erstellen können. Mit den erhaltenen Informationen können die Anbieter ihre eigenen Marketingmaßnahmen verbessern. Neben Pixel-Tags können solche Informationen (wie beispielsweise welchen Button Sie klicken oder wann Sie welche Seite aufrufen) auch in Cookies gespeichert werden. Darin können neben Analysedaten zu Ihrem Webverhalten auch technische Informationen wie etwa Ihr Browsertyp oder Ihr Betriebssystem gespeichert werden. Manche Anbieter können die gewonnenen Daten auch mit anderen internen Diensten oder auch mit Drittanbieter verknüpfen. Jeder Anbieter pflegt einen anderen Umgang mit Ihren Daten. Daher empfehlen wir Ihnen sorgfältig die Datenschutzerklärungen der jeweiligen Dienste durchzulesen. Wir sind grundsätzlich bemüht, nur Dienste zu verwenden, die mit dem Thema Datenschutz sehr vorsichtig umgehen.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Dauer der Datenverarbeitung</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist.</p>
<h3 className="mt-6 text-xl font-semibold leading-7 text-indigo-600">Rechtsgrundlage</h3>
<p className="mt-6 text-lg leading-8 text-gray-600">Wenn wir Sie um Ihre Einwilligung bitte und Sie auch einwilligen, dass wir den Dienst verwenden dürfen, gilt dies als Rechtsgrundlage der Verarbeitung Ihrer Daten (Art. 6 Abs. 1 lit. a DSGVO).  Zusätzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Die Rechtsgrundlage dafür ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Informationen zu den speziellen Tools, erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="trello-datenschutzerklaerung" >Trello Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir nutzen für unsere Website die Projektmanagement-Tool Trello. Dienstanbieter ist das amerikanische Unternehmen Trello Inc., 55 Broadway New York, NY 10006, USA. Das Mutterunternehmen ist Atlassian Inc., 1098 Harrison Street, San Francisco, California 94103, USA.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Trello verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Trello sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Trello, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="noreferrer" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr Informationen zu den Standardvertragsklauseln bei Trello finden Sie unter <a  href="https://community.atlassian.com/t5/Trust-Security-articles/New-Atlassian-Standard-Contractual-Clauses-SCC/ba-p/1846231">https://community.atlassian.com/t5/Trust-Security-articles/New-Atlassian-Standard-Contractual-Clauses-SCC/ba-p/1846231</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr über die Daten, die durch die Verwendung von Trello verarbeitet werden, erfahren Sie in der Datenschutzerklärung auf <a  href="https://trello.com/privacy?tid=312492666">https://trello.com/privacy</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="auftragsverarbeitungsvertrag-avv-trello" >Auftragsverarbeitungsvertrag (AVV) Trello</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Trello einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, können Sie in unserem allgemeinen Abschnitt „Auftragsverarbeitungsvertrag (AVV)“ nachlesen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Dieser Vertrag ist gesetzlich vorgeschrieben, weil Trello in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird geklärt, dass Trello Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <a  href="https://www.atlassian.com/legal/data-transfer-impact-assessment" target="_blank" rel="noreferrer noopener">https://www.atlassian.com/legal/data-transfer-impact-assessment</a>.</p>
<h2 className="mt-6 text-2xl font-bold tracking-tight mt-2 text-gray-900"id="zapier-datenschutzerklaerung" >Zapier Datenschutzerklärung</h2>
<p className="mt-6 text-lg leading-8 text-gray-600">Wir verwenden für unserer Website die Automatisierungssoftware Zapier. Dienstanbieter ist das amerikanische Unternehmen Zapier Inc., 548 Market Street 6241, San Francisco, CA 94104, USA.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Zapier verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten (außerhalb der Europäischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Zapier sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Zapier, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a  href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Die Datenverarbeitungsbedingungen (Data Processing Agreements), welche den Standardvertragsklauseln entsprechen, finden Sie unter <a  href="https://zapier.com/help/account/data-management/standard-contractual-clauses-at-zapier">https://zapier.com/help/account/data-management/standard-contractual-clauses-at-zapier</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Mehr Informationen über die Daten, die durch die Verwendung von Zapier verarbeitet werden, erfahren Sie in der Privacy Policy auf <a  href="https://zapier.com/privacy?tid=312492666">https://zapier.com/privacy</a>.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">Alle Texte sind urheberrechtlich geschützt.</p>
<p >Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" title="Datenschutz Generator von AdSimple für Deutschland">Datenschutz Generator</a> von AdSimple</p>

                <dl className="mt-10 max-w-xl space-y-8 mt-6 text-xl leading-7 text-gray-600 lg:max-w-none">
                    
                    <div  className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900"></dt>
                        
                        <dd className="inline"></dd>
                    </div>
                   
                </dl>
                </div>
            </div>
            
            </div>
        </div>
        </div>
        </div>
      );
  }
  
  export default Datenschutz;